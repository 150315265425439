import { PiStepsDuotone } from "react-icons/pi";
import { GiWindow, GiWindowBars } from "react-icons/gi";
import { TbWindow } from "react-icons/tb";

import ScaleSmall from '../img/scale-small.png';
import PersianeSmall from '../img/persiane-small.png';
import FuturaSmall from '../img/futura-small.png';
import VetrateSmall from '../img/vetrate-small-1.png';

const services = [
  {
    id: 1,
    icon: <PiStepsDuotone className="text-white text-[30px] lg:text-[70px] xl:text-[100px]" />,
    name: "Scale",
    image: ScaleSmall,
    href: "/product/scale-e-corrimano",
  },
  {
    id: 2,
    icon: <GiWindow className="text-white text-[30px] lg:text-[70px] xl:text-[100px]" />,
    name: "Persiane",
    image: PersianeSmall,
    href: "/product/persiane",
  },
  {
    id: 3,
    icon: <GiWindowBars className="text-white text-[30px] lg:text-[70px] xl:text-[100px]" />,
    name: "Grate",
    image: FuturaSmall,
    href: "/product/grate",
  },
  {
    id: 4,
    icon: <TbWindow className="text-white text-[30px] lg:text-[70px] xl:text-[100px]" />,
    name: "Vetrate",
    image: VetrateSmall,
    href: "/product/vetrate",
  },
];

export default services;