import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/ui/Layout";
import ToSHero from "../components/ToSHero";

export default function TermsOfService() {
  return (
    <>
      <Helmet>
        <title>Termini e Condizioni - Ferro Marrocco</title>
      </Helmet>
      <section className="w-full h-screen bg-black bg-opacity-50 z-10">
        <Layout>
          <ToSHero />
        </Layout>
      </section>
    </>
  );
}