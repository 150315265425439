import React from "react";
import sections from "../assets/data/stats";

export default function Stats() {
  return (
    <section id="stats" className="bg-[#406580] anchor">
      <div className="flex flex-wrap justify-center items-center p-5 mx-auto lg:grid lg:grid-cols-4">
        {sections.map((section, index) => (
          <div key={index} className={`flex flex-col items-center mb-10 lg:mb-0 ${index < sections.length - 1 ? 'lg:border-r border-gray-300' : ''}`}>
            <div className="rounded-lg mb-4">
              {React.cloneElement(section.icon, { className: "w-16 h-16 text-gray-300" })}
            </div>
            <h3 className="text-lg font-semibold text-white mb-2">{section.title}</h3>
            <p className="text-gray-400 text-sm text-center max-w-xs">{section.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
}