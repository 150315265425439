import React from "react";

export default function Quote() {
  return (
    <section id="reviews" className="bg-[#faf8f5] min-h-[350px] p-10 relative mask flex items-center justify-center anchor">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-[#ebd7b2] text-center text-[45px] font-bold z-10">Marrocco Group Srls</h1>
        <h1 className="text-white text-center text-[30px] font-bold z-10">La soluzione migliore per la tua sicurezza</h1>
        <button className="bg-[#ebd7b2] text-gray-800 text-[20px] font-bold py-2 px-4 rounded-full mt-5 z-10 border-2 border-gray-800 hover:bg-[#ebcd96]">Preventivo Gratuito</button>
      </div>
    </section>
  );
}