import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/ui/Layout";
import PrivacyHero from "../components/PrivacyHero";

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Ferro Marrocco</title>
      </Helmet>
      <section className="w-full h-screen bg-black bg-opacity-50 z-10">
        <Layout>
          <PrivacyHero />
        </Layout>
      </section>
    </>
  );
}