import React from "react";
import BackgroundImage from '../assets/img/background-2.jpg';

export default function ContactsHero() {
  return (
    <div className="absolute w-full h-full z-0">
      <div className="bg-opacity-50 opacity-70 z-0">
        <img src={BackgroundImage} alt="Background" className="object-cover w-full h-[80vh]" />
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex flex-col md:items-start items-center md:mx-24 justify-start mt-52 z-10">
        <div className="bg-black bg-opacity-60 p-4 rounded-md md:p-8">
          <div className="text-center ">
            <h1 className="text-white text-[16px] md:text-[25px] font-bold tracking-widest">Artisti del Ferro dal 1965</h1>
          </div>
          <div className="border-b-2 border-[#406580] w-full my-[2px]"></div>
          <div className="text-center">
            <h1 className="text-white text-[25px] md:text-[40px] font-bold tracking-widest">MARROCCO</h1>
          </div>
          <div className="text-center">
            <h1 className="text-white text-[25px] md:text-[40px] font-bold -mt-2">I VOSTRI ARTISTI DEL FERRO</h1>
          </div>
          <div className="text-center">
            <h1 className="text-white text-[25px] md:text-[40px] font-bold -mt-2 md:-mt-4">DI FIDUCIA</h1>
          </div>
          <div className="text-center">
            <button className="bg-[#00a7ec] text-gray-800 text-[20px] font-bold py-1 px-2 rounded-full mt-1 md:mt-2 z-10 border-2 border-gray-800 hover:bg-[#006eec]">Vieni a trovarci</button>
          </div>
        </div>
      </div>
    </div>
  );
}