import React from "react";
import { useWindowWidth } from "../utils/useWidth";
import { FaChevronDown } from 'react-icons/fa';
import { Link } from "react-router-dom";
import services from "../assets/data/services";

export default function Section1() {
  const width = useWindowWidth();
  const breakpoint = 769;
  return (
    <section className="bg-white">
      {width > breakpoint ? (
        <div className="relative left-1/2 -translate-x-1/2 flex flex-col items-center justify-center z-10 gap-8 bg-[#406580] h-[200px] shadow shadow-black">
          <div className="flex items-center justify-center gap-4 transform -translate-y-[100px]">
          {services.map(service => (
            <Link to={service.href} className="flex flex-col items-center justify-center gap-2 relative" key={service.id}>
              <div className="md:w-[135px] md:h-[150px] lg:w-[189px] lg:h-[210px] xl:w-[243px] xl:h-[270px] 2xl:w-[270px] 2xl:h-[300px] rounded-lg overflow-hidden" style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${service.image})` }}></div>
              <div className="middle transition-opacity duration-500 ease-in-out absolute top-0 left-0 w-full h-full bg-[#004265] bg-opacity-0 opacity-100 hover:opacity-0 flex items-center rounded-lg justify-center">
                <div className="flex flex-col items-center justify-center gap-2">
                  {service.icon}
                  <p className="text-white text-xl font-bold tracking-wider mt-2">{service.name}</p>
                </div>
              </div>
              <div className="middle transition-opacity duration-500 ease-in-out absolute top-0 left-0 w-full h-full bg-[#004265] bg-opacity-80 opacity-100 hover:opacity-40 flex items-center rounded-lg justify-center">
                <div className="flex flex-col items-center justify-center gap-2">
                  {service.icon}
                  <p className="text-white text-xl font-bold tracking-wider mt-2">{service.name}</p>
                </div>
              </div>
              <button className="absolute bottom-0 left-1/2 transform -translate-x-1/2 text-white text-lg p-3 translate-y-1/2 rounded-full border-2 border-white hover:bg-white hover:text-[#004265] transition-all duration-300 ease-in-out">
                <FaChevronDown />
              </button>
            </Link>
          ))}
          </div>
        </div>
      ) : (
        <div className="relative flex flex-col items-center justify-center gap-3 -mt-40">
          {services.map((service) => (
            <Link to={service.href} className="flex flex-col items-center justify-center bg-red relative w-[90%]" key={service.id}>
              <div className="w-full h-[90px] rounded-lg overflow-hidden" style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${service.image})` }}></div>
              <div className="transition-opacity duration-500 ease-in-out w-full h-full bg-[#004265] bg-opacity-80 opacity-100 hover:opacity-40 flex items-center justify-center rounded-lg absolute top-0 left-0">
                <div className="flex flex-col items-center justify-center gap-2">
                  {service.icon}
                  <p className="text-white text-lg font-bold tracking-wider mt-2">{service.name}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </section>
  );
}