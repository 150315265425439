// WOODEN Colors
import acacia from "../img/colors/acacia.jpg";
import frassino from "../img/colors/frassino.jpg";
import marrone from "../img/colors/marrone-3d.jpg";
import teak from "../img/colors/teak.jpg";
import rovereSbiancato from "../img/colors/rovere-sbiancato.jpg";
import faggio from "../img/colors/faggio.jpg";
import rovere from "../img/colors/rovere.jpg";
import douglas from "../img/colors/douglas.jpg";
import rovereGrigio from "../img/colors/rovere-grigio.jpg";
import corten from "../img/colors/corten.jpg";
import renolit from "../img/colors/renolit.jpg";
import ciliegio from "../img/colors/ciliegio.jpg";
import renolitScuro from "../img/colors/renolit-scuro.jpg";
import ciliegioRosso from "../img/colors/ciliegio-rosso.jpg";
import pino from "../img/colors/pino.jpg";
import castagno from "../img/colors/castagno.jpg";
import querciaNaturale from "../img/colors/quercia-naturale.jpg";
import ciliegio317 from "../img/colors/ciliegio-317.jpg";
import olmo from "../img/colors/olmo.jpg";
import biancoRovere from "../img/colors/bianco-rovere.jpg";
import querciaInvecchiata from "../img/colors/quercia-invecchiata.jpg";
import ciliegio317Chiaro from "../img/colors/ciliegio-317-chiaro.jpg";
import noce3d from "../img/colors/noce-3d.jpg";
import acero from "../img/colors/acero.jpg";
import noceAnticato from "../img/colors/noce-anticato.jpg";
import verdeRovere from "../img/colors/verde-rovere.jpg";
import noce from "../img/colors/noce.jpg";

// MARBLE Colors
import effettoMarmoDiCarrara from "../img/colors/effetto-marmo-di-carrara.jpg";
import effettoMarmoTravertino from "../img/colors/effetto-marmo-travertino.jpg";
import effettoMarmoVerdeGuatema from "../img/colors/effetto-marmo-verde-guatema.jpg";
import effettoMarmoVerona from "../img/colors/effetto-marmo-verona.jpg";

// SPRAY Colors
import verdeSable from "../img/colors/verde-sable.jpg";
import gold from "../img/colors/gold.jpg";
import ral6005_raggrinzato from "../img/colors/ral6005_raggrinzato.jpg";
import marroneMarmoLiscio from "../img/colors/marrone_marmo_liscio.jpg";
import verdeSilver from "../img/colors/verde-silver.jpg";
import ghiaccio from "../img/colors/ghiaccio.jpg";
import ral6005_bucciato from "../img/colors/ral6005_bucciato.jpg";
import marroneCuoio from "../img/colors/marrone_cuoio.jpg";
import verdePuntinato from "../img/colors/verde-puntinato.jpg";
import biancoStucco from "../img/colors/bianco-stucco.jpg";
import ral3003Bucciato from "../img/colors/ral3003_bucciato.jpg";
import marroneAntico from "../img/colors/marrone_anticoi.jpg";
import silver from "../img/colors/silver.jpg";
import biancoQuarzo from "../img/colors/bianco-quarzo.jpg";
import ral8017Bucciato from "../img/colors/ral_8017_bucciato.jpg";
import grigioMetallizatoSemilucido from "../img/colors/grigio_metallizzato_semiluc.jpg";
import ral9007Raggrinzato from "../img/colors/ral-9007-raggrinzato.jpg";
import biancoPuntinato from "../img/colors/bianco-puntinato.jpg";
import oroBond from "../img/colors/oro_bond.jpg";
import grigioMarmoLiscio from "../img/colors/grigio_marmo_liscio.jpg";
import marroneSilver from "../img/colors/marrone-silver.jpg";
import biancoPietra from "../img/colors/bianco-pietra.jpg";
import neroSilver from "../img/colors/nero_silver.jpg";
import grigioGrafite from "../img/colors/grigio_grafite.jpg";
import argetoSilver from "../img/colors/argeto-silver.jpg";
import neroRamato from "../img/colors/nero_ramato.jpg";
import grigioAntracite from "../img/colors/grigio_antracite.jpg";
import legnoMix from "../img/colors/legno-mix.jpg";
import verdeRamato from "../img/colors/verde_ramato.jpg";
import neroRal9005Raggrinzato from "../img/colors/nero_ral9005_raggrinzato.jpg";
import grigioAntigraffio from "../img/colors/grigio_antigraffio.jpg";
import indianBrown from "../img/colors/indian-brown.jpg";
import verderMarmoLiscio from "../img/colors/verde_marmo_liscio.jpg";
import neroBronzo from "../img/colors/nero_bronzo.jpg";
import grigioAntico from "../img/colors/grigio_antico.jpg";
import grigioSable from "../img/colors/grigio-sable-1.jpg";
import verdeAntico from "../img/colors/verde_antico.jpg";
import neroOro from "../img/colors/nero_oro.jpg";
import biancoRamato from "../img/colors/bianco-ramato.jpg";
import grigioPuntinatoLucido from "../img/colors/grigio-puntinato-lucido.jpg";
import ral9010Bucciato from "../img/colors/ral9010_bucciato.jpg";
import neroAntracite from "../img/colors/nero_antracite.jpg";
import greyBrown from "../img/colors/grey-brown.jpg";
import ral9006Grigio from "../img/colors/ral9006_grigio.jpg";
import marroneRamato from "../img/colors/marrone_ramato.jpg";

// MATERIC Colors
import verdeOssido from "../img/colors/verde-ossido.jpg";
import rameLucido from "../img/colors/rame-lucido.jpg";
import ottoneSatinato from "../img/colors/ottone-satinato.jpg";
import bronzoBrunto from "../img/colors/bronzo-brunto.jpg";
import rameBruinito from "../img/colors/rame-brunito.jpg";
import oro from "../img/colors/oro.jpg";
import corten1 from "../img/colors/corten-1.jpg";
import ottoneBrunto from "../img/colors/ottone-brunto.jpg";
import rameVerde from "../img/colors/rame-verde.jpg";
import bluLaminazione from "../img/colors/blu-laminazione.jpg";
import acciaioBrunito from "../img/colors/acciaio-brunito.jpg";
import bronzoDeabre from "../img/colors/bronzo-delabre.jpg";
import acciaioSpazzolato from "../img/colors/acciaio-spazzolato.jpg";

const colorsWooden = [
  { name: "acacia", img: acacia },
  { name: "frassino", img: frassino },
  { name: "marrone", img: marrone },
  { name: "teak", img: teak },
  { name: "rovere sbiancato", img: rovereSbiancato },
  { name: "faggio", img: faggio },
  { name: "rovere", img: rovere },
  { name: "douglas", img: douglas },
  { name: "rovere grigio", img: rovereGrigio },
  { name: "corten", img: corten },
  { name: "renolit", img: renolit },
  { name: "ciliegio", img: ciliegio },
  { name: "renolit scuro", img: renolitScuro },
  { name: "ciliegio rosso", img: ciliegioRosso },
  { name: "pino", img: pino },
  { name: "castagno", img: castagno },
  { name: "quercia naturale", img: querciaNaturale },
  { name: "ciliegio317", img: ciliegio317 },
  { name: "olmo", img: olmo },
  { name: "bianco rovere", img: biancoRovere },
  { name: "quercia invecchiata", img: querciaInvecchiata },
  { name: "ciliegio317 chiaro", img: ciliegio317Chiaro },
  { name: "noce 3d", img: noce3d },
  { name: "acero", img: acero },
  { name: "noce anticato", img: noceAnticato },
  { name: "verde rovere", img: verdeRovere },
  { name: "noce", img: noce },
]

const colorsMarble = [
  { name: "effetto marmo di carrara", img: effettoMarmoDiCarrara },
  { name: "effetto marmo di travertino", img: effettoMarmoTravertino },
  { name: "effetto marmo verde guatemala", img: effettoMarmoVerdeGuatema },
  { name: "effetto marmo verona", img: effettoMarmoVerona },
]

const colorsSpray = [
  { name: "verde sable", img: verdeSable },
  { name: "gold", img: gold },
  { name: "ral6005 raggrinzato", img: ral6005_raggrinzato },
  { name: "marrone marmo liscio", img: marroneMarmoLiscio },
  { name: "verde silver", img: verdeSilver },
  { name: "ghiaccio", img: ghiaccio },
  { name: "ral6005 bucciato", img: ral6005_bucciato },
  { name: "marrone cuoio", img: marroneCuoio },
  { name: "verde puntinato", img: verdePuntinato },
  { name: "bianco stucco", img: biancoStucco },
  { name: "ral3003 bucciato", img: ral3003Bucciato },
  { name: "marrone antico", img: marroneAntico },
  { name: "silver", img: silver },
  { name: "bianco quarzo", img: biancoQuarzo },
  { name: "ral8017 bucciato", img: ral8017Bucciato },
  { name: "grigio metallizato semilucido", img: grigioMetallizatoSemilucido },
  { name: "ral9007 raggrinzato", img: ral9007Raggrinzato },
  { name: "bianco puntinato", img: biancoPuntinato },
  { name: "oro bond", img: oroBond },
  { name: "grigio marmo liscio", img: grigioMarmoLiscio },
  { name: "marrone silver", img: marroneSilver },
  { name: "bianco pietra", img: biancoPietra },
  { name: "nero silver", img: neroSilver },
  { name: "grigio grafite", img: grigioGrafite },
  { name: "argeto silver", img: argetoSilver },
  { name: "nero ramato", img: neroRamato },
  { name: "grigio antracite", img: grigioAntracite },
  { name: "legno mix", img: legnoMix },
  { name: "verde ramato", img: verdeRamato },
  { name: "nero ral9005 raggrinzato", img: neroRal9005Raggrinzato },
  { name: "grigio antigraffio", img: grigioAntigraffio },
  { name: "indian brown", img: indianBrown },
  { name: "verde marmo liscio", img: verderMarmoLiscio },
  { name: "nero bronzo", img: neroBronzo },
  { name: "grigio antico", img: grigioAntico },
  { name: "grigio sable", img: grigioSable },
  { name: "verde antico", img: verdeAntico },
  { name: "nero oro", img: neroOro },
  { name: "bianco ramato", img: biancoRamato },
  { name: "grigio puntinato lucido", img: grigioPuntinatoLucido },
  { name: "ral9010 bucciato", img: ral9010Bucciato },
  { name: "nero antracite", img: neroAntracite },
  { name: "grey brown", img: greyBrown },
  { name: "ral9006 grigio", img: ral9006Grigio },
  { name: "marrone ramato", img: marroneRamato },
]

const colorsMateric = [
  { name: "verde ossido", img: verdeOssido },
  { name: "rame lucido", img: rameLucido },
  { name: "ottone satinato", img: ottoneSatinato },
  { name: "bronzo brunto", img: bronzoBrunto },
  { name: "rame brunito", img: rameBruinito },
  { name: "oro", img: oro },
  { name: "corten 1", img: corten1 },
  { name: "ottone brunto", img: ottoneBrunto },
  { name: "rame verde", img: rameVerde },
  { name: "blu laminazione", img: bluLaminazione },
  { name: "acciaio brunito", img: acciaioBrunito },
  { name: "bronzo delabre", img: bronzoDeabre },
  { name: "acciaio spazzolato", img: acciaioSpazzolato },
]

export { colorsWooden, colorsSpray, colorsMarble, colorsMateric }