import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { colorsWooden, colorsSpray, colorsMarble, colorsMateric } from "../assets/data/colors"
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";

export default function ColorsHero() {
  const [isCollapsedWooden, setIsCollapsedWooden] = useState(false);
  const [isCollapsedMarble, setIsCollapsedMarble] = useState(false);
  const [isCollapsedSpray, setIsCollapsedSpray] = useState(false);
  const [isCollapsedMateric, setIsCollapsedMateric] = useState(false);

  const itemsColorsWooden = colorsWooden.map((image, index) => (
    <>
      <img
        key={index}
        src={image.img}
        alt={image.name}
        style={{ height: `${Math.floor(Math.random() * 200) + 170}px`, width: "100%", borderRadius: "16px", padding: "8px", objectFit: "cover" }}
      />
      <p className="text-white ml-3">{image.name}</p>
    </>
  ));
  const itemsColorsMarble = colorsMarble.map((image, index) => (
    <>
      <img
        key={index}
        src={image.img}
        alt={image.name}
        style={{ height: `${Math.floor(Math.random() * 200) + 170}px`, width: "100%", borderRadius: "16px", padding: "8px", objectFit: "cover" }}
      />
      <p className="text-white ml-3">{image.name}</p>
    </>
  ));
  const itemsColorsSpray = colorsSpray.map((image, index) => (
    <>
      <img
        key={index}
        src={image.img}
        alt={image.name}
        style={{ height: `${Math.floor(Math.random() * 200) + 170}px`, width: "100%", borderRadius: "16px", padding: "8px", objectFit: "cover" }}
      />
      <p className="text-white ml-3">{image.name}</p>
    </>
  ));
  const itemsColorsMateric = colorsMateric.map((image, index) => (
    <>
      <img
        key={index}
        src={image.img}
        alt={image.name}
        style={{ height: `${Math.floor(Math.random() * 200) + 170}px`, width: "100%", borderRadius: "16px", padding: "8px", objectFit: "cover" }}
      />
      <p className="text-white ml-3">{image.name}</p>
    </>
  ));

  const toggleCollapseWooden = () => {
    setIsCollapsedWooden(!isCollapsedWooden);
  }
  const toggleCollapseMarble = () => {
    setIsCollapsedMarble(!isCollapsedMarble);
  }
  const toggleCollapseSpray = () => {
    setIsCollapsedSpray(!isCollapsedSpray);
  }
  const toggleCollapseMateric = () => {
    setIsCollapsedMateric(!isCollapsedMateric);
  }

  return (
    <>
      <section className="mx-4 lg:mx-12 z-10 mt-32 mb-14 bg-[#1f2127]">
        <h2 className="text-lg lg:text-2xl font-bold text-white flex justify-between items-center">
          Colori Effetto Legno
          <button onClick={toggleCollapseWooden} className="focus:outline-none text-lg lg:text-2xl">
            {isCollapsedWooden ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </h2>
        <div className={`border-b-2 border-[#406580] w-full my-2`}></div>
        <div className={`flex flex-row justify-between mb-4 ${isCollapsedWooden ? 'hidden' : ''}`}>
          <div className="flex flex-col items-start justify-start w-1/4 invisible">.</div>
          <div className="w-full h-full">
            <ResponsiveMasonry columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 5, 900: 7 }}>
              <Masonry>{itemsColorsWooden}</Masonry>
            </ResponsiveMasonry>
          </div>
        </div>

        <h2 className="text-lg lg:text-2xl font-bold text-white flex justify-between items-center mt-4">
        Colori Effetto Marmo
          <button onClick={toggleCollapseMarble} className="focus:outline-none text-lg lg:text-2xl">
            {isCollapsedMarble ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </h2>
        <div className={`border-b-2 border-[#406580] w-full my-2`}></div>
        <div className={`flex flex-row justify-between mb-4 ${isCollapsedMarble ? 'hidden' : ''}`}>
          <div className="flex flex-col items-start justify-start w-1/4 invisible">.</div>
          <div className="w-full h-full">
            <ResponsiveMasonry columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 5, 900: 7 }}>
              <Masonry>{itemsColorsMarble}</Masonry>
            </ResponsiveMasonry>
          </div>
        </div>

        <h2 className="text-lg lg:text-2xl font-bold text-white flex justify-between items-center mt-4">
        Verniciatura a Polvere
          <button onClick={toggleCollapseSpray} className="focus:outline-none text-lg lg:text-2xl">
            {isCollapsedSpray ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </h2>
        <div className={`border-b-2 border-[#406580] w-full my-2`}></div>
        <div className={`flex flex-row justify-between mb-4 ${isCollapsedSpray ? 'hidden' : ''}`}>
          <div className="flex flex-col items-start justify-start w-1/4 invisible">.</div>
          <div className="w-full h-full">
            <ResponsiveMasonry columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 5, 900: 7 }}>
              <Masonry>{itemsColorsSpray}</Masonry>
            </ResponsiveMasonry>
          </div>
        </div>

        <h2 className="text-lg lg:text-2xl font-bold text-white flex justify-between items-center mt-4">
        Finitura Materica
          <button onClick={toggleCollapseMateric} className="focus:outline-none text-lg lg:text-2xl">
            {isCollapsedMateric ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </h2>
        <div className={`border-b-2 border-[#406580] w-full my-2`}></div>
        <div className={`flex flex-row justify-between mb-4 ${isCollapsedMateric ? 'hidden' : ''}`}>
          <div className="flex flex-col items-start justify-start w-1/4 invisible">.</div>
          <div className="w-full h-full">
            <ResponsiveMasonry columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 5, 900: 7 }}>
              <Masonry>{itemsColorsMateric}</Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
      </section>
    </>
  );
}