import React from "react";

export default function ContactInfo() {
  return (
    <section className="w-full z-10 mt-12 bg-[#1f2127]">
      <div className="flex flex-col items-center justify-center mb-4">
        <div className="border-t-2 border-[#406580] w-24 my-4"></div>
        <div className="text-center">
          <h1 className="text-white text-[25px] md:text-[40px] font-bold tracking-widest">Contatti</h1>
          <p className="text-gray-200 text-[16px] md:text-[20px] font-light mx-2 lg:mx-56 xl:mx-80">
            Morrocco è una rinomata azienda di ferro battuto di Marino a conduzione familiare. Lavoriamo con passione e dedizione nel settore da oltre 30 anni e siamo specializzati nella realizzazione di cancelli, inferriate, ringhiere, scale, recinzioni e strutture in ferro battuto.
          </p>
        </div>
        <div className="text-center mt-4">
          <h1 className="text-white text-[25px] md:text-[40px] font-bold tracking-widest">Orari di apertura</h1>
            <p className="text-gray-200 text-[16px] md:text-[20px] font-light mx-2 lg:mx-56 xl:mx-80">
              Lunedì - Venerdì: 08:00-13:00 14:00-17:00
            </p>
            <p className="text-gray-200 text-[16px] md:text-[20px] font-light mx-2 lg:mx-56 xl:mx-80">
              Sabato: 08:00-13:00
            </p>
            <p className="text-gray-200 text-[16px] md:text-[20px] font-light mx-2 lg:mx-56 xl:mx-80">
              Domenica: Chiuso
            </p>
        </div>
        <div className="border-b-2 border-[#406580] w-24 my-4"></div>
        <div className="border-b-2 border-[#406580] w-16 -mt-2"></div>
        <div className="border-b-2 border-[#406580] w-10 mt-2"></div>
      </div>
    </section>
  );
}