import React from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '25vh',
};
const center = {
  lat: 41.7659193, // default latitude
  lng: 12.6117065, // default longitude
};

const options = {
  styles: [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        { saturation: -100 },
        { lightness: 0 }
      ]
    }
  ],
  disableDefaultUI: true,
  gestureHandling: 'cooperative',
  draggable: false,
};

export default function SimpleMap() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyD3X6FL_xeBjTovFuWp5n_NBablr9QJs-A', //remove all the dashes at the end of the key -----
    libraries,
  });

  const [selected, setSelected] = React.useState(null);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={16}
        center={center}
        options={options}
      >
        <Marker 
          position={center}
          onClick={() => {
            setSelected(center);
          }}
        />

        {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div className="text-center">
              <h2 className="mx-auto block">Ferro Marrocco</h2>
              <p className="mx-auto block mt-1 mb-3">Via Luigi Einaudi 10, Marino RM</p>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  );
};