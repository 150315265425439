import React, { useState, useEffect } from "react";
import { Analytics } from '@vercel/analytics/react';
import Header from "./Header";
import Footer from "./Footer";

export default function Layout({ children }) {
  const [cookiesAccepted, setCookiesAccepted] = useState(new Set());
  const [consentId, setConsentId] = useState(null);

  useEffect(() => {
    const handleConsent = ({ detail }) => {
      setCookiesAccepted(new Set(detail.cookie.categories));
      setConsentId(detail.cookie.consentId);
    };

    window.addEventListener('cc:onConsent', handleConsent);

    return () => {
      window.removeEventListener('cc:onConsent', handleConsent);
    };
  }, []);

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (cookiesAccepted.has('analytics')) {
      // Microsoft Clarity Tag
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "nj4xiql7gw");
  
      // Google Tag (gtag.js)
      const gtagScript = document.createElement('script');
      gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-C9RRM1FB9D";
      gtagScript.async = true;
      document.head.appendChild(gtagScript);
  
      gtagScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){window && window.dataLayer && window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-C9RRM1FB9D');
      };
    }
  }, [cookiesAccepted]);
  

  console.log("[Cookies] Consent ID:", consentId);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow bg-[#1f2127]">
        {children}
      </main>
      {cookiesAccepted.has('analytics') && <Analytics />}
      <Footer />
    </div>
  );
}