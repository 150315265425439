import React from "react";

export default function PrivacyHero() {
  return (
    <section className="w-full z-10 mt-32 bg-[#1f2127]">
      <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start">
        <h1 className="text-white text-3xl font-bold">Informativa sulla Privacy</h1>
        <h2 className="text-gray-200 lg:text-lg text-sm mx-12 ml-0">
          Tutti i dati forniti dai clienti NON saranno ceduti ad aziende terze per nessun motivo e verranno trattati secondo le vigenti normative.
          Inoltre ferromarrocco.com NON tiene banca dati cartacea o qualsiasi tipo di elenco clienti.
          NON verranno per nessun motivo spedite lettere o materiale pubblicitario agli indirizzi forniti per la spedizione..
        </h2>
      </div>
      <div className="mt-8 mb-16">
        <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start mt-4">
          <h3 className="text-white md:text-xl text-base font-bold">Informativa e consenso ai sensi dell'art. 7 del D.lgs. 196/03 (“codice della privacy)</h3>
          <h3 className="text-white md:text-xl text-base font-base">Si informa che:</h3>
          <ul className="text-gray-300 lg:text-lg text-sm list-disc md:pl-5 ml-3 mt-1 mx-12">
            <li>
              I “dati personali” vengono trattati da bsdesign.it esclusivamente per attività commerciali e gestionali
            </li>
            <li>
              Il conferimento dei dati è richiesto esclusivamente ai fini della spedizione dell’ordine.
            </li>
            <li>
              I predetti dati NON saranno comunicati ad altre società. In relazione al trattamento dei predetti dati, il cliente ha diritto di ottenere senza ritardo a cura di Marrocco Group Srls:
              <ul className="list-disc md:pl-5 ml-3 mt-1 mx-12">
                <li>
                  La conferma dell’esistenza di dati personali che lo riguardano e la comunicazione dei medesimi dati e della loro origine, nonché della logica su cui si basa il trattamento.
                </li>
                <li>
                  La cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compreso quelli di cui non è necessaria la conservazioni in relazione agli scopi per i quali i dati sono stati raccolti;
                </li>
                <li>
                  L’aggiornamento, la rettificazione ovvero, qualora vi abbia interesse, l’integrazione dei dati; Il cliente ha inoltre diritto di conoscere, mediante accesso al registro di cui D.lgs. 196/03 (“codice della privacy), l’esistenza del trattamento di dati che possono riguardarlo e di opporsi in tutto o in parte, per motivi legittimi, al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo scopo della raccolta nonché di opporsi, esercitando gratuitamente tale diritto, al trattamento di dati.
                </li>
              </ul>
            </li>
          </ul>
          <h3 className="text-white md:text-xl text-base font-bold mt-3">Ulteriore Informativa ai sensi della legge sulla privacy (L 196/2003)</h3>
          <p className="text-gray-300 lg:text-lg text-sm mx-12 ml-0 mt-2">
            Marrocco Group Srls, ai sensi della Legge 196/2003 garantisce a tutti i propri clienti i diritti previsti dalla suddetta legge. I dati personali saranno trattati ESCLUSIVAMENTE per la spedizione della merce, per i servizi amministrativi e contabili.
            I dati sensibili di tutti i clienti sono periodicamente cancellati dallo staff diMarrocco Group Srls.
          </p>
        </div>
        
      </div>
    </section>
  );
}