import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { Accordion } from "flowbite-react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";

import Layout from "../components/ui/Layout";
import NotFound from "../pages/NotFound";
import { products } from "../assets/data/products";

export default function Products() {
  const { slug } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);

  if (!slug || !products.find(product => product.id === slug)) {
    return <NotFound />;
  }

  const product = products.find(product => product.id === slug);
  console.log(product);

  const openLightbox = (images, index) => {
    setCurrentImages(images);
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  return (
    <>
      <Helmet>
        {product ? (
          <>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <meta name="description" content="Scopri i nostri prodotti e crea ambienti sicuri e confortevoli per lavorare e vivere. Soddisfiamo le esigenze delle persone contemporaneamente al desiderio di creare." />
            <meta name="keywords" content="Ferro Marrocco, Artisti del Ferro, Ferro, Marrocco, Artisti, Sicurezza, Ambienti, Lavorare, Vivere, Prodotti, Creare, Esigenze, Contemporaneamente, Desiderio, Persone" />
            
            <link rel="canonical" href={`https://ferromarrocco.com/products/${product.id}`} />

            <title>{product.title} | Ferro Marrocco</title>
          </>
        ) : (
          <title>Ferro Marrocco</title>
        )}
      </Helmet>
      <section className="w-full h-screen bg-black bg-opacity-50 z-10">
        <Layout>
          <section className="w-full z-10 mt-28 bg-[#1f2127]">
            <div className="flex flex-col items-start justify-center w-full px-6 lg:px-24">
              <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li className="inline-flex items-center">
                  <Link to={"/"} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                    <FaHome className="w-3 h-3 mr-1" />
                    Home
                  </Link>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                    </svg>
                    <Link to={"/products"} className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">Products</Link>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                    </svg>
                    <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">{product.title}</span>
                  </div>
                </li>
              </ol>
            </div>

            <div className="flex flex-col md:flex-row items-center md:items-start justify-center w-full px-6 lg:px-36 my-12">
              <div className="flex flex-col items-center justify-center md:w-1/2">
                <img src={product.main} alt={product.title} className="w-auto h-[500px] rounded-xl" />
              </div>
              <div className="flex flex-col items-center md:items-start justify-start md:w-1/2 mt-4 md:mt-0">
                <h1 className="text-4xl font-bold text-white">{product.title}</h1>
                <p className="text-lg text-white mt-2">{product.description}</p>
                <p className="text-lg text-gray-400 mt-6">{product.text}</p>
                {/* contact button to ask for free quote */}
                <Link to="mailto:marroccogroupsrls@gmail.com?subject=Richiesta%20Preventivo%20Ferro%20Marrocco" className="mt-6 px-6 py-2 text-white bg-[#406580] rounded-lg hover:bg-[#1d506b] transition duration-300 ease-in-out">Richiedi un preventivo gratuitamente</Link>
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center md:items-start justify-center w-full px-6 2xl:px-48 lg:px-36 my-12">
              {/* return the accordition only if there is data and if something is present inside the array */}
              {product.data && Object.keys(product.data).length > 0 && (
                <Accordion className="w-full">
                  {Object.keys(product.data).map((key, index) => (
                    <Accordion.Panel key={index}>
                      <Accordion.Title>{product.data[key].title}</Accordion.Title>
                      <Accordion.Content>
                        {product.data[key].description && (
                          <p className="mb-2 text-gray-500 dark:text-gray-400">
                            {product.data[key].description}
                          </p>
                        )}
                        {product.data[key].img.length > 0 && (
                          <div className="w-full mx-2">
                            <ResponsiveMasonry columnsCountBreakPoints={{ 300: 1, 500: 2, 700: 4, 900: 6 }}>
                              <Masonry>
                                {product.data[key].img.map((image, imgIndex) => (
                                  <img
                                    key={imgIndex}
                                    src={image}
                                    alt="gallery"
                                    style={{ width: "100%", borderRadius: "15px", padding: "5px" }}
                                    className="cursor-pointer"
                                    onClick={() => openLightbox(product.data[key].img, imgIndex)}
                                  />
                                ))}
                              </Masonry>
                            </ResponsiveMasonry>
                          </div>
                        )}
                      </Accordion.Content>
                    </Accordion.Panel>
                  ))}
                </Accordion>
              )}
            </div>
          </section>
        </Layout>
      </section>
      {isOpen && (
        <Lightbox
          styles={{ container: { backgroundColor: "rgba(0, 0, 0, 0.95)" } }}
          plugins={[Counter, Fullscreen, Slideshow, Thumbnails, Zoom]}
          slides={currentImages.map(src => ({ src }))}
          index={currentImageIndex}
          open={isOpen}
          close={() => setIsOpen(false)}
        />
      )}
    </>
  );
}