import React from "react";
import BackgroundImage from '../assets/img/background-1.jpg';

export default function Hero() {
  return (
    <div className="absolute w-full h-full z-0">
      <div className="bg-opacity-50 opacity-70 z-0">
        <img src={BackgroundImage} alt="Background" className="object-cover w-full h-[80vh]" />
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-start mt-52 z-10">
        <div className="text-center">
          <h1 className="text-[#fae5be] text-[16px] md:text-[20px] font-bold tracking-widest">Marrocco Group</h1>
        </div>
        <div className="text-center">
          <h1 className="text-white text-[30px] md:text-[55px] font-bold">Artisti del Ferro Battuto</h1>
        </div>
        <div className="text-center">
          <h1 className="text-white text-[30px] md:text-[55px] font-bold -mt-1">Da oltre 30 anni</h1>
        </div>
      </div>
    </div>
  );
}
