import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";

import Sabbiatura1 from "../assets/img/criosabbiatura1.png";
import Sabbiatura2 from "../assets/img/criosabbiatura2.png";
import Sabbiatura3 from "../assets/img/criosabbiatura3.png";
import Sabbiatura4 from "../assets/img/criosabbiatura4.png";
import Sabbiatura5 from "../assets/img/criosabbiatura5.png";
import Sabbiatura6 from "../assets/img/criosabbiatura6.png";

const vantaggi = [
  {
    title: "PROCESSO A SECCO",
    description: "E’ un sistema di pulizia a secco che permette innumerevoli applicazioni laddove non è possibile utilizzare acqua o sabbia."
  },
  {
    title: "ECOLOGICO",
    description: "E’ un sistema completamente pulito ed ecologico che non fa uso di sostanze chimiche e non produce o aggiunge anidride carbonica nell’atmosfera e perciò non contribuisce all’effetto serra."
  },
  {
    title: "NESSUN RESIDUO",
    description: "Non ci sono rifiuti di lavorazione da smaltire infatti non vengono impiegati né acqua, né sabbia, né prodotti chimici."
  },
  {
    title: "AUMENTO DELLA PRODUTTIVITA’",
    description: "Le pause per la manutenzione si riducono poiché non si rendono più necessarie le operazioni di smontaggio e riassemblaggio spesso le macchine possono essere pulite in funzione, in modo da aumentare il tempo di produzione effettivo."
  },
  {
    title: "EFFICACE",
    description: "E’ possibile effettuare la pulizia anche in punti di difficile accessibilità grazie agli ugelli a getto e alle differenti velocità di pulitura."
  },
  {
    title: "NON ABRASIVO",
    description: "Non danneggia la superficie da pulire, in quanto il ghiaccio secco una volta a contatto con la superficie da pulire sublima e pertanto non crea abrasioni o striature tipiche dei sistemi di sabbiatura tradizionali."
  },
  {
    title: "SICUREZZA SU COMPONENTI ELETTRICI",
    description: "Elimina significativi accumuli senza danneggiare parti delicate, come componenti elettrici, interruttori, fili, sensori fotoelettrici, ecc.."
  },
  {
    title: "RIDUZIONE DEL TEMPO DI FERMO",
    description: "Riduce il tempo di fermo delle macchine durante le operazioni di pulizia, permettendo una ripresa più rapida delle attività produttive."
  },
];

const industries = ["Industria del legno", "Industria gomma e plastica", "Industria elettrica", "Industria edile", "Industria meccanica", "Industria alimentare","Industria manifatturiera", "Industria grafica", "Industria nautica"];
const images = [Sabbiatura1, Sabbiatura2, Sabbiatura3, Sabbiatura4, Sabbiatura5, Sabbiatura6];

export default function ServicesHero() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const items = images.map((image, index) => (
    <img
      key={index}
      src={image}
      alt="gallery"
      style={{ width: "100%", borderRadius: "15px", padding: "5px" }}
      className="cursor-pointer"
      onClick={() => openLightbox(index)}
    />
  ));

  return (
    <>
      <section id="reviews" className="bg-[#faf8f5] mt-24 max-h-[200px] p-10 relative mask-services flex items-center justify-center anchor">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-[#ebd7b2] text-center text-[45px] font-bold z-10">I Nostri Servizi</h1>
        </div>
      </section>
      <section className="w-full z-10 mt-5 bg-[#1f2127] mb-16">
        <div className="container mx-auto h-full flex flex-col items-center justify-start">
          <h1 className="text-white text-center text-[30px] font-bold z-10 tracking-widest">CRIOSABBIATURA</h1>
          <div className="border-b-2 border-[#406580] w-full mt-5 mb-2"></div>
          <p className="text-white text-center text-[20px] z-10">
            La <span className="text-[#00a7ec]">criosabbiatura</span> è una <span className="text-[#00a7ec]">tecnica all’avanguardia</span> nel settore della pulizia. Siamo in grado di fornire un <span className="text-[#00a7ec]">procedimento di pulizia</span> estremamente <span className="text-[#00a7ec]">efficace, asciutto, non abrasivo ed ecologico,</span> utilizzabile in svariate aree industriali per <span className="text-[#00a7ec]">eliminare vernici, sporco, grasso o strati simili</span> dalla superficie di materiali, in maniera accurata.
          </p>

          <h1 className="text-white text-center text-[30px] mt-8 font-bold z-10 tracking-widest">Vantaggi</h1>
          <div className="border-b-2 border-[#406580] w-4/5 mt-5 mb-4"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 z-10 mx-2">
            {vantaggi.map((item, index) => (
              <div key={index} className="bg-[#00a5eca7] p-4 rounded-md">
                <h1 className="text-white text-[20px] font-bold">{item.title}</h1>
                <p className="text-white text-[16px]">{item.description}</p>
              </div>
            ))}
          </div>

          <h1 className="text-white text-center text-[30px] mt-8 font-bold z-10 tracking-widest">Campi di Applicazioni</h1>
          <div className="border-b-2 border-[#406580] w-4/5 mt-5 mb-4"></div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mx-2">
            {industries.map((item, index) => (
              <div key={index} className="relative">
                <div className="absolute inset-0 border-2 border-[#406580] transform translate-x-2 -translate-y-2 z-0 rounded-md"></div>
                <div className="relative bg-[#00a5ec] p-4 rounded-md z-10">
                  <h1 className="text-white md:text-[15px] xl:text-[20px] font-bold">{item}</h1>
                </div>
              </div>
            ))}
          </div>
          
          <h1 className="text-white text-center text-[30px] mt-8 font-bold z-10 tracking-widest">Galleria</h1>
          <div className="border-b-2 border-[#406580] w-4/5 mt-5 mb-4"></div>
          <div className="w-full mx-2">
            <ResponsiveMasonry columnsCountBreakPoints={{ 300: 1, 500: 2, 700: 3, 900: 3 }}>
              <Masonry>{items}</Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
      </section>
      {isOpen && (
        <Lightbox
          styles={{ container: { backgroundColor: "rgba(0, 0, 0, 0.95)" } }}
          plugins={[Counter, Fullscreen, Slideshow, Thumbnails, Zoom]}
          slides={images.map(src => ({ src }))}
          index={currentImageIndex}
          open={isOpen}
          close={() => setIsOpen(false)}
        />
      )}
    </>
  );
}