import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/ui/Layout";
import CookiesHero from "../components/CookiesHero";

export default function Cookies() {
  return (
    <>
      <Helmet>
        <title>Termini e Condizioni - Ferro Marrocco</title>
      </Helmet>
      <section className="w-full h-screen bg-black bg-opacity-50 z-10">
        <Layout>
          <CookiesHero />
        </Layout>
      </section>
    </>
  );
}