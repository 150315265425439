import React from "react";
import { Link } from "react-router-dom";
import SimpleMap from "../../features/Map";

import whatsappLogo from '../../assets/img/whatsapp.svg';
import footerLogo from '../../assets/img/footer-white2a.png';

export default function Footer() {
  return (
    <footer>
      <section className="map">
        <SimpleMap />
      </section>

      <section className="footer w-full bg-[#1f2127] text-white">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <a href="https://ferromarrocco.com/" className="flex items-center">
                <img src={footerLogo} alt="Ferro Marrocco" className="h-10 mr-2" />
              </a>
              <p className="mt-4 text-gray-500 dark:text-gray-400 max-w-sm">
                Siamo specializzati nella lavorazione del ferro e dell’acciaio per la produzione in particolare di sistemi di sicurezza
              </p>
            </div>
            <div className="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Apertura</h2>
                <dl className="text-gray-500 dark:text-gray-400 font-medium space-y-2">
                  <div className="flex justify-start">
                    <dt className="mr-4">Lun - Ven</dt>
                    <dd>08:00 - 17:00</dd>
                  </div>
                  <div className="flex justify-start">
                    <dt className="mr-4">Sabato</dt>
                    <dd>08:00 - 13:00</dd>
                  </div>
                  <div className="flex justify-start">
                    <dt className="mr-4">Domenica</dt>
                    <dd>Chiuso</dd>
                  </div>
                </dl>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Informative</h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-2">
                    <Link href="#" className="hover:underline" to={"/privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li className="mb-2">
                    <Link href="#" className="hover:underline" to={"/terms-of-service"}>Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <Link href="#" className="hover:underline" to={"/cookies"}>Cookies</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Socials</h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-2">
                    <a href="tel:+390631054072" className="hover:underline">Telefono</a>
                  </li>
                  <li className="mb-2">
                    <a href="https://www.instagram.com/marroccogroup/" className="hover:underline">Instagram</a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/people/Marrocco-Group/61552063162756/?sk=reels_tab" className="hover:underline">Facebook</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="footer w-full h-0.5 bg-[#333333]"></section>
      
      <section className="footer w-full h-14 flex items-center justify-center text-center bg-[#1f2127] text-gray-200 text-xs">
        &copy; {new Date().getFullYear()} Marrocco Group. P.IVA 14211871000. All rights reserved. Sviluppato da <a href="https://github.com/fanto6314" className="hover:underline ml-1 text-gray-400">Antonio Foresta</a>
      </section>
      
      <Link className="fixed bottom-7 right-5 bg-white rounded-full px-4 py-1.5 flex items-center justify-center z-200" to={"tel:+3423971162"}>
        <img src={whatsappLogo} alt="WhatsApp" className="mr-3 w-5" />
        <span className="text-[#333333] text-[14px] font-bold tracking-wide">CONTATTACI</span>
      </Link>
    </footer>
  );
}