import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/ui/Layout";
import ServicesHero from "../components/ServicesHero";

export default function Services() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Scopri i nostri servizi e crea ambienti sicuri e confortevoli per lavorare e vivere. Soddisfiamo le esigenze delle persone contemporaneamente al desiderio di creare." />
        <meta name="keywords" content="Ferro Marrocco, Artisti del Ferro, Ferro, Marrocco, Artisti, Sicurezza, Ambienti, Lavorare, Vivere, Prodotti, Creare, Esigenze, Contemporaneamente, Desiderio, Persone" />
        
        <link rel="canonical" href="https://ferromarrocco.com/services" />

        <title>Servizi - Ferro Marrocco</title>
      </Helmet>
      <section className="w-full h-screen bg-black bg-opacity-50 z-10">
        <Layout>
          <ServicesHero />
        </Layout>
      </section>
    </>
  );
}