import { MdOutlineReviews } from "react-icons/md";
import { GrWorkshop } from "react-icons/gr";
import { FaQuoteLeft } from "react-icons/fa6";
import { VscWorkspaceTrusted } from "react-icons/vsc";

const sections = [
  {
    alt: "100% Soddisfatto",
    title: "100% Soddisfatto",
    text: "Siamo orgogliosi di offrire un servizio clienti di alta qualità con un tasso di soddisfazione del 100%.",
    icon: <MdOutlineReviews />
  },
  { 
    alt: "35 anni d'esperienza", 
    title: "35 anni d'esperienza", 
    text: "Con oltre 35 anni di esperienza, siamo in grado di soddisfare le esigenze di qualsiasi progetto.", 
    icon: <GrWorkshop />
  },
  { 
    alt: "Preventivi in 48 Ore", 
    title: "Preventivi in 48 Ore", 
    text: "Riceverai il tuo preventivo entro 48 ore dalla richiesta, garantendo un servizio rapido e affidabile.", 
    icon: <FaQuoteLeft />
  },
  { 
    alt: "Prodotti garantiti 5 Anni", 
    title: "Prodotti garantiti 5 Anni", 
    text: "Tutti i nostri prodotti sono garantiti per 5 anni, offrendoti la tranquillità che meriti.", 
    icon: <VscWorkspaceTrusted />
  },
];

export default sections;