import React from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { productsHero as products } from "../assets/data/products";

import ceLogo from "../assets/img/ce-logo.png";
import bvCertifcation from "../assets/img/bv-certification.png";
import JansenCertification from "../assets/img/jansen-certification.png";
import InstedilCertification from "../assets/img/istedil-certification.png";

export default function ProductsHero() {
  return (
    <section className="mx-4 lg:mx-12 z-10 mt-32 mb-14 bg-[#1f2127]">
      <div className="max-w-screen-xl mx-auto 2xl:mx-28">
        <h1 className="text-white text-3xl lg:text-6xl font-semibold">I nostri prodotti</h1>
        <p className="text-gray-400 text-lg lg:text-xl mt-4 lg:w-2/3">“Tecnologia d’Avanguardia – Affidabilità – Sicurezza per un prodotto che guarda al futuro”.</p>
        <p className="text-gray-400 text-lg lg:text-xl mt-4 lg:w-2/3">L’elevata tecnologia, la sicurezza, l’estetica, il costo contenuto, sono le caratteristiche principali dei nostri prodotti, progettati per soddisfare ogni possibile richiesta ed esigenza della nostra clientela, sempre nel totale rispetto dell’impatto estetico ed ambientale.</p>
      </div>
      <div className="max-w-screen-xl mx-auto 2xl:mx-28 mt-6">
        <h1 className="text-white text-xl lg:text-3xl font-semibold">Le nostre certificazioni</h1>
      </div>
      <div className="flex mx-4 md:mx-24 xl:mx-80 justify-center mt-8">
        <Marquee gradient={false} speed={40} className="mt-8">
          <div className="flex items-center space-x-6 md:space-x-10">
            {[...Array(3)].map((_, index) => (
              <>
                <img src={ceLogo} alt="CE Logo" className="h-16" />
                <img src={bvCertifcation} alt="BV Certification" className="h-16" />
                <img src={JansenCertification} alt="Jansen Certification" className="h-16" />
                <img src={InstedilCertification} alt="Instedil Certification" className="h-12" />
              </>
            ))}
          </div>
        </Marquee>
      </div>
      <div className="border-b-2 border-[#406580] w-full mt-8"></div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-10">
        {products.map((product, index) => (
          <div key={index} className="bg-[#406580] rounded-lg shadow-lg border-[3px] border-[#406580]">
            <img src={product.image} alt={product.title} className="w-full h-48 object-cover rounded-t-lg" />
            <div className="p-4 flex flex-col items-center">
              <h2 className="text-xl font-semibold text-white">{product.title}</h2>
              <p className="text-gray-300 text-sm mt-2">{product.description}</p>
                <Link to={product.to} className="bg-[#00a7ec] text-gray-800 text-[15px] font-bold py-1 px-4 rounded-full mt-1 md:mt-2 z-10 hover:bg-[#006eec] flex items-center">
                  Dettagli
                  <FaLongArrowAltRight className="ml-2" />  
                </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}