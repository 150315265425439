import React from "react";

export default function ToSHero() {
  return (
    <section className="w-full z-10 mt-32 bg-[#1f2127]">
      <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start">
        <h1 className="text-white text-3xl font-bold">Informativa sui Termini e le Condizioni</h1>
        <h2 className="text-gray-200 lg:text-lg text-sm mx-12 ml-0">Di seguito troverai i termini e le condizioni di utilizzo del nostro sito web.</h2>
      </div>
      <div className="mt-8 mb-16">
        <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start mt-4">
          <h3 className="text-white md:text-xl text-base font-bold">Disposizioni di carattere generale</h3>
          <ul className="text-gray-300 lg:text-lg text-sm list-disc md:pl-5 ml-3 mt-1 mx-12">
            <li>
              Le presenti Condizioni Generali di Vendita (CGV) regolano l’acquisto, tramite modalità telematiche, di ogni prodotto (Prodotto, al plurale Prodotti) presentato sul sito https://ferromarrocco.com (Sito), di proprietà dell’Impresa in Luigi Einaudi, 10, 00040 Marino RM (IT), P.IVA 14211871000, marroccogroupsrls@gmail.com, proprietario del marchio Ferro Marrocco (altresì detta Venditore) e il cliente (Cliente o Acquirente).
            </li>
            <li>
              Le presenti CGV costituiscono parte integrante ed essenziale del Contratto di acquisto (Contratto) e sarà possibile stamparne una copia.
            </li>
            <li>
              Si precisa, inoltre, che le previsioni delle CGV che facciano riferimento a consumatori troveranno applicazione esclusivamente nei confronti di soggetti che ricadano nella relativa definizione. I Consumatori beneficeranno, in particolare, delle tutele previste in caso di conclusione di contratti a distanza ai sensi del Titolo III, Sezione II, del D.Lgs. 6 settembre 2005 n. 206 (Codice del Consumo).
            </li>
            <li>
              Si precisa, inoltre, che, stante la natura artigianale dei beni venduti sul Sito, trovano applicazione le norme di cui all’art. 59 del Codice del Consumo, inerenti alle “Eccezioni al diritto di recesso” (cfr. Art. 5).
            </li>
            <li>
            All’interno delle presenti CGV trovano applicazione le seguenti definizioni: “Consumatore”: qualsiasi persona fisica che effettui un ordine per scopi estranei all’attività imprenditoriale, commerciale, artigianale o professionale eventualmente svolta; “Professionista”: qualsiasi persona fisica o giuridica che effettui un ordine nell’esercizio della propria attività imprenditoriale, commerciale, artigianale o professionale; “Cliente o Acquirente”: a seconda dei casi, un Consumatore o un Professionista; “Contratto”: ogni accordo concluso attraverso il Sito ovvero attraverso corrispondenza elettronica, tra Momentum Glass e un Cliente circa l’acquisto da parte di quest’ultimo di uno o più Prodotti; “Ordine”: ogni proposta riguardante l’acquisto di uno o più Prodotti, formulata dal Cliente nei confronti di Momentum Glass.
            </li>
          </ul>
        </div>
        <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start mt-6">
          <h3 className="text-white md:text-xl text-base font-bold">Oggetto e perfezionamento del Contratto</h3>
          <ul className="text-gray-300 lg:text-lg text-sm list-disc md:pl-5 ml-3 mt-1 mx-12">
            <li>
              Oggetto del Contratto è la vendita di uno o più Prodotti descritti dettagliatamente nella sezione “ONLINE SHOP” del Sito al prezzo ivi indicato, comprensivo delle spese di spedizione e delle tasse e delle imposte applicabili, salvo la richiesta, concordata dall’Acquirente con il Venditore antecedentemente al pagamento, di spedizioni diverse da quelle normalmente praticate dal Venditore.
            </li>
            <li>
              Si evidenzia che i Prodotti venduti da Momentum Glass sono oggetto di produzione artigianale e, pertanto, eventuali imperfezioni che potessero essere presenti sono da considerarsi comunque proprie dell’attività manuale posta in essere e non potranno in nessun caso costituire vizio ai sensi dell’art. 1490 c.c.
            </li>
            <li>
              Qualora il Prodotto, presente su www.ferromarrocco.com, non sia disponibile al momento d’accesso al Sito ovvero al momento dell’invio dell’ordine, sarà cura del Venditore comunicare, tempestivamente ed in ogni caso entro 30 (trenta) giorni a decorrere dal giorno successivo a quello in cui l’ordine è stato trasmesso al Venditore, l’eventuale indisponibilità del Prodotto ordinato.
            </li>
            <li>
              In caso d’inoltro dell’ordine e pagamento del prezzo, il Venditore provvederà a rimborsare, senza grave ritardo, quanto anticipato, ed il Contratto si intenderà risolto tra le parti, salvo diverso accordo tra il Venditore ed il Cliente.
            </li>
            <li>
              La procedura per la conclusione del Contratto sul Sito inizia con l’inserimento nel carrello elettronico del Prodotto, cliccando sul tasto della pagina web “Acquista”, continuando, poi, nella trasmissione dell’ordine al Venditore per via telematica, seguendo le relative istruzioni.
            </li>
            <li>
              All’esito della procedura, il Cliente verrà indirizzato alla pagina web di Paypal, all’interno della quale troverà l’indicazione d’inserimento dei propri dati e del mezzo di pagamento utilizzato.
            </li>
            <li>
              Il Contratto è definitivamente concluso quando BS Design riceve telematicamente l’ordine d’acquisto, previa verifica della correttezza dei dati inseriti relativamente all’ordine di acquisto stesso.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}