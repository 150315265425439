import React from "react";

export default function CookiesHero() {
  return (
    <section className="w-full z-10 mt-32 bg-[#1f2127]">
      <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start">
        <h1 className="text-white text-3xl font-bold">Informativa sui Cookie</h1>
        <h2 className="text-gray-200 lg:text-lg text-sm mx-12 ml-0">Il nostro sito utilizza i cookie per garantire la migliore esperienza di navigazione possibile.</h2>
      </div>
      <div className="mt-8 mb-16">
        <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start mt-4">
          <h3 className="text-white md:text-xl text-base font-bold">Cosa sono i cookie?</h3>
          <ul className="text-gray-300 lg:text-lg text-sm list-disc md:pl-5 ml-3 mt-1 mx-12">
            <li>
              I cookie sono piccoli file di testo che vengono memorizzati sul tuo computer o dispositivo mobile quando visiti un sito web. Questi file di testo memorizzano informazioni che possono essere lette dal sito web quando lo visiti di nuovo in futuro.
            </li>
          </ul>
        </div>
        <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start mt-4">
          <h3 className="text-white md:text-xl text-base font-bold">A cosa servono i Cookie?</h3>
          <ul className="text-gray-300 lg:text-lg text-sm list-disc md:pl-5 ml-3 mt-1 mx-12">
            <li>
              I cookies sono delle piccole stringhe di testo inviate da un sito web al visitatore, e successivamente rimandate indietro per la lettura da parte del server. Ogni volta che l’utente torna sullo stesso sito web, il browser recupera e invia il file al server del sito. I cookies sono quindi utilizzati principalmente per ricordare le scelte degli utenti (per es. la lingua parlata) e per riconoscere i visitatori ricorrenti di un sito web, poiché di per sé le pagine web non hanno alcuna ‘memoria’. In generale, la finalità dei cookies è migliorare il funzionamento del sito web e l’esperienza dell’utente nell’utilizzo stesso.
            </li>
          </ul>
        </div>
        <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start mt-4">
          <h3 className="text-white md:text-xl text-base font-bold">I cookie sono tutti uguali?</h3>
          <ul className="text-gray-300 lg:text-lg text-sm list-disc md:pl-5 ml-3 mt-1 mx-12">
            <li>
              No, esistono vari tipi di cookies, che vengono utilizzati a seconda dell’esigenza. I cookies si distinguono per funzione, durata e origine.
            </li>
          </ul>
        </div>
        <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start mt-4">
          <h3 className="text-white md:text-xl text-base font-bold">Come utilizza i cookies questo sito web?</h3>
          <ul className="text-gray-300 lg:text-lg text-sm list-disc md:pl-5 ml-3 mt-1 mx-12">
            <li>
              Cookies tecnici: consentono di visualizzare questo sito web, di farlo funzionare correttamente, migliorando l’esperienza di navigazione per esempio creando un account, effettuando il login, scegliendo la lingua, gestendo le funzionalità del sito web. I cookies tecnici sono indispensabili affinché questo sito web funzioni correttamente.
              Cookies analitici, per capire meglio come i nostri utenti utilizzano questo sito web. Tra i dati che ricaviamo figurano le pagine web visitate, le pagine di uscita e di ingresso, il tipo di piattaforma, informazioni su data e ora e dati come il numero di click con il mouse su una determinata pagina. Queste informazioni sono anonime, e utilizzate esclusivamente a fini statistici.
              Per quanto tempo restano attivi i cookies di questo sito web?
              questo sito web utilizza cookies di durata variabile. La durata massima, che interessa solo alcuni dei nostri cookies, è pari a 360 giorni. Tutti i cookies possono essere cancellati dal browser su richiesta dell’utente, in qualunque momento.
            </li>
          </ul>
        </div>
        <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start mt-4">
          <h3 className="text-white md:text-xl text-base font-bold">Come faccio a riconoscere i cookies di questo sito web?</h3>
          <ul className="text-gray-300 lg:text-lg text-sm list-disc md:pl-5 ml-3 mt-1 mx-12">
            <li>
              Puoi trovare i cookies di questo sito web tra le impostazioni del tuo browser.
            </li>
          </ul>
        </div>
        <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start mt-4">
          <h3 className="text-white md:text-xl text-base font-bold">Chi ha accesso ai dati dei Cookie?</h3>
          <ul className="text-gray-300 lg:text-lg text-sm list-disc md:pl-5 ml-3 mt-1 mx-12">
            <li>
              Solo questo sito web può accedere ai dati che derivano dai cookies di questo sito web, i dati sono completamente anonimi e resteranno strettamente riservati.
            </li>
          </ul>
        </div>
        <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start mt-4">
          <h3 className="text-white md:text-xl text-base font-bold">Questo sito web controlla il mio comportamento online?</h3>
          <ul className="text-gray-300 lg:text-lg text-sm list-disc md:pl-5 ml-3 mt-1 mx-12">
            <li>
              No. Analizziamo a fini statistici il comportamento degli utenti solo all’interno del nostro sito web.
            </li>
          </ul>
        </div>
        <div className="container lg:mx-auto mx-4 h-full flex flex-col items-start justify-start mt-4">
          <h3 className="text-white md:text-xl text-base font-bold">Come posso modificare le preferenze relative ai cookies?</h3>
          <ul className="text-gray-300 lg:text-lg text-sm list-disc md:pl-5 ml-3 mt-1 mx-12">
            <li>
              Nelle impostazioni di ogni browser (Internet Explorer, Safari, Firefox, Chrome ecc…) potrai stabilire quali sono i cookies che desideri ricevere e quali no. Per sapere dove puoi trovare suddette impostazioni, utilizza il tasto Help” del tuo browser. ” Alcuni cookies, se rifiutati, potrebbero impedire il corretto funzionamento del nostro sito web.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}