import CombinatoGif from "../vid/animazione-apertura-1e.gif";
import PersianeGif from "../img/persiane1.png";
import PersianaCentroStoricoGif from "../img/principale-500x667.jpg";
import PortelloniGif from "../vid/apertura-b5.gif";
import GrateGif from "../img/grate1.png";
import GrateSnodoGif from "../img/grate-snodo1.png";
import GrateFuturaGif from "../img/GRATA-FUTURA-VISSTA-ESTERNA.jpg";
import PortoniFerroGif from "../img/portoni-ferro2.png";
import CancelliRinghiereGif from "../img/IMG_1266.jpg";
import ScaleCorrimanoGif from "../img/interni-1.jpg";
import VetrateGif from "../img/PHOTO-2019-05-06-14-29-04.jpg";
import TantoAltroGif from "../img/IMG-2925-scaled.jpg";

//Combinato
import Combinato1 from "../img/combinato/AX_8867_1.jpg";
import Combinato2 from "../img/combinato/AX_8872_1.jpg";
import Combinato3 from "../img/combinato/AX_8866_1.jpg";
import Combinato4 from "../img/combinato/AX_8885_1.jpg";
import Combinato5 from "../img/combinato/AX_8883_1.jpg";
import Combinato6 from "../img/combinato/AX_8888_1.jpg";
import Combinato7 from "../img/combinato/AX_6095-scaled.jpg";
import Combinato8 from "../img/combinato/AX_6098-scaled.jpg";
import Combinato9 from "../img/combinato/AX_8880_1.jpg";
import Combinato10 from "../img/combinato/AX_8873_1.jpg";
import Combinato11 from "../img/combinato/AX_8875_1.jpg";
import Combinato12 from "../img/combinato/certificato-combinata1-210x300.jpg";
import Combinato13 from "../img/combinato/AX_8859_1.jpg";
import Combinato14 from "../img/combinato/AX_8862_1.jpg";
import Combinato15 from "../img/combinato/comb-1.jpg";
import Combinato16 from "../img/combinato/comb-3.jpg";
import Combinato17 from "../img/combinato/comb-2.jpg";
import Combinato18 from "../img/combinato/695.jpg";
import Combinato19 from "../img/combinato/694.jpg";
import Combinato20 from "../img/combinato/693.jpg";
import Combinato21 from "../img/combinato/692.jpg";
import Combinato22 from "../img/combinato/690.jpg";
import Combinato23 from "../img/combinato/689.jpg";
import Combinato24 from "../img/combinato/686.jpg";
import Combinato25 from "../img/combinato/688.jpg";
import Combinato26 from "../img/combinato/687.jpg";
import Combinato27 from "../img/combinato/685.jpg";
import Combinato28 from "../img/combinato/684.jpg";
import Combinato29 from "../img/combinato/700.jpg";
import Combinato30 from "../img/combinato/699.jpg";
import Combinato31 from "../img/combinato/698.jpg";

//Persiane
import Persiane1 from "../img/persiane/persiane2.png";
import Persiane2 from "../img/persiane/persiane3.png";
import Persiane3 from "../img/persiane/persiane7.png";
import Persiane4 from "../img/persiane/persiane8.png";
import Persiane5 from "../img/persiane/persiane9.png";
import Persiane6 from "../img/persiane/persiane10.png";
import Persiane7 from "../img/persiane/persiane11.png";
import Persiane8 from "../img/persiane/persiane4.png";
import Persiane9 from "../img/persiane/A-LIBRO-4-ANTE.jpg";
import Persiane10 from "../img/persiane/PERSIANA-APERTA-rotated.jpg";
import Persiane11 from "../img/persiane/PERSIANA-ROMANA-NEGOZIO-2-scaled.jpg";
import Persiane12 from "../img/persiane/DSC00416-scaled.jpg";
import Persiane13 from "../img/persiane/100_0054.jpg";
import Persiane14 from "../img/persiane/100_0055.jpg";
import Persiane15 from "../img/persiane/100_0071.jpg";

//Persiana Centro Storico
import PersianaCentroStorico1 from "../img/persiane-storico/1637578983361-scaled.jpg";
import PersianaCentroStorico2 from "../img/persiane-storico/1637578983364-scaled.jpg";
import PersianaCentroStorico3 from "../img/persiane-storico/1637578983368-scaled.jpg";
import PersianaCentroStorico4 from "../img/persiane-storico/1637580465867-scaled.jpg";
import PersianaCentroStorico5 from "../img/persiane-storico/1637580465871-1-scaled.jpg";
import PersianaCentroStorico6 from "../img/persiane-storico/1637578983372-scaled.jpg";
import PersianaCentroStorico7 from "../img/persiane-storico/1637580232843-scaled.jpg";
import PersianaCentroStorico8 from "../img/persiane-storico/DSC01398.jpg";
import PersianaCentroStorico9 from "../img/persiane-storico/DSC01399.jpg";
import PersianaCentroStorico10 from "../img/persiane-storico/persiana-centro-storico.jpg";
import PersianaCentroStorico11 from "../img/persiane-storico/DSC01396.jpg";
import PersianaCentroStorico12 from "../img/persiane-storico/1637580955148-scaled.jpg";
import PersianaCentroStorico13 from "../img/persiane-storico/1637580955154-scaled.jpg";
import PersianaCentroStorico14 from "../img/persiane-storico/1637580955141-scaled.jpg";

//Portelloni
import Portelloni1 from "../img/portelloni/AX_0316.jpg";
import Portelloni2 from "../img/portelloni/AX_0319.jpg";
import Portelloni3 from "../img/portelloni/AX_0320.jpg";
import Portelloni4 from "../img/portelloni/AX_6056.jpg";
import Portelloni5 from "../img/portelloni/AX_6060.jpg";
import Portelloni6 from "../img/portelloni/AX_6062.jpg";
import Portelloni7 from "../img/portelloni/AX_0347.jpg";
import Portelloni8 from "../img/portelloni/AX_0349.jpg";
import Portelloni9 from "../img/portelloni/AX_6148.jpg";
import Portelloni10 from "../img/portelloni/AX_6149.jpg";
import Portelloni11 from "../img/portelloni/AX_6146.jpg";
import Portelloni12 from "../img/portelloni/AX_0330.jpg";
import Portelloni13 from "../img/portelloni/AX_6047.jpg";
import Portelloni14 from "../img/portelloni/AX_6083.jpg";
import Portelloni15 from "../img/portelloni/AX_6139.jpg";
import Portelloni16 from "../img/portelloni/AX_6120.jpg";
import Portelloni17 from "../img/portelloni/AX_6050.jpg";
import Portelloni18 from "../img/portelloni/AX_6068.jpg";
import Portelloni19 from "../img/portelloni/AX_6072.jpg";
import Portelloni20 from "../img/portelloni/AX_6082.jpg";
import Portelloni21 from "../img/portelloni/AX_6089.jpg";
import Portelloni22 from "../img/portelloni/AX_6129.jpg";
import Portelloni23 from "../img/portelloni/AX_6133.jpg";
import Portelloni24 from "../img/portelloni/AX_6137.jpg";
import Portelloni25 from "../img/portelloni/AX_6143.jpg";
import Portelloni26 from "../img/portelloni/AX_6154.jpg";
import Portelloni27 from "../img/portelloni/AX_6122.jpg";
import Portelloni28 from "../img/portelloni/AX_6093.jpg";
import Portelloni29 from "../img/portelloni/AX_03071.jpg";
import Portelloni30 from "../img/portelloni/AX_0309.jpg";
import Portelloni31 from "../img/portelloni/AX_0310.jpg";
import Portelloni32 from "../img/portelloni/AX_6112.jpg";
import Portelloni33 from "../img/portelloni/AX_0345.jpg";
import Portelloni34 from "../img/portelloni/AX_6111-scaled.jpg";
import Portelloni35 from "../img/portelloni/AX_0332-scaled.jpg";
import Portelloni36 from "../img/portelloni/AX_0332-copia-scaled.jpg";

//Grate
import Grate1 from "../img/grate/grate2.png";
import Grate2 from "../img/grate/grate3.png";
import Grate3 from "../img/grate/GRATA-DETTAGLIO-PALETTO.jpg";
import Grate4 from "../img/grate/PHOTO-2021-11-18-12-15-51.jpg";
import Grate5 from "../img/grate/DSC_7586.jpg";
import Grate6 from "../img/grate/grate5.png";
import Grate7 from "../img/grate/GRATA-DETTAGLIO-CERNIERA-E-ROSTRO.jpg";
import Grate8 from "../img/grate/GRATA-BIANCA-NEGOZIO-DETTAGLIO-ROSTRO-NEGOZIO-2.jpg";
import Grate9 from "../img/grate/grate6.png";

import Grate10 from "../img/grate/DSC00415-scaled.jpg";
import Grate11 from "../img/grate/101_0127.jpg";
import Grate12 from "../img/grate/101_0194.jpg";
import Grate13 from "../img/grate/grata.jpg";
import Grate14 from "../img/grate/Giuseppe0001.jpg";
import Grate15 from "../img/grate/grata-img.jpg";
import Grate16 from "../img/grate/IMG_5512-rotated.jpg";

//grate a snodo
import GrateSnodo1 from "../img/grate-snodo/grate-snodo2.png";
import GrateSnodo2 from "../img/grate-snodo/grate-snodo4.png";
import GrateSnodo3 from "../img/grate-snodo/grate-snodo5.png";
import GrateSnodo4 from "../img/grate-snodo/grate-snodo6.png";
import GrateSnodo5 from "../img/grate-snodo/grate-snodo3.png";
import GrateSnodo6 from "../img/grate-snodo/grate-snodo7.png";
import GrateSnodo7 from "../img/grate-snodo/grate-snodo8.png";

//grate futura
import GrateFutura1 from "../img/grata-futura/futura2.png";
import GrateFutura2 from "../img/grata-futura/futura3.png";
import GrateFutura3 from "../img/grata-futura/futura4.png";
import GrateFutura4 from "../img/grata-futura/1637575072735-scaled.jpg";
import GrateFutura5 from "../img/grata-futura/1637575072739-scaled.jpg";
import GrateFutura6 from "../img/grata-futura/1637575072743-scaled.jpg";
import GrateFutura7 from "../img/grata-futura/1637575072727-scaled.jpg";
import GrateFutura8 from "../img/grata-futura/futura5.png";
import GrateFutura9 from "../img/grata-futura/futura6.png";
import GrateFutura10 from "../img/grata-futura/futura7.png";
import GrateFutura11 from "../img/grata-futura/GRATA-FUTURA-DETTAGLIO-CERNIERA.jpg";
import GrateFutura12 from "../img/grata-futura/certificato-grate-210x300.jpg";
import GrateFutura13 from "../img/grata-futura/futura1.png";
import GrateFutura14 from "../img/grata-futura/grata-fututra-1.jpg";

//portoni in ferro
import PortoniFerro1 from "../img/portoni-ferro/portoni-ferro1.png";
import PortoniFerro2 from "../img/portoni-ferro/portoni-ferro2.png";
import PortoniFerro3 from "../img/portoni-ferro/portoni-ferro5.png";
import PortoniFerro4 from "../img/portoni-ferro/IMG_5512-1.jpg";

//cancelli e ringhiere
import CancelliRinghiere1 from "../img/cancelli-ringhiere/D924EA45-E8B4-46EA-BE2B-EC67C9C814F0-rotated.jpg";
import CancelliRinghiere2 from "../img/cancelli-ringhiere/IMG_1266.jpg";
import CancelliRinghiere3 from "../img/cancelli-ringhiere/IMG_1267.jpg";
import CancelliRinghiere4 from "../img/cancelli-ringhiere/cancelli.png";
import CancelliRinghiere5 from "../img/cancelli-ringhiere/cancelli8.png";
import CancelliRinghiere6 from "../img/cancelli-ringhiere/100_0074.jpg";
import CancelliRinghiere7 from "../img/cancelli-ringhiere/DSC00434-scaled.jpg";
import CancelliRinghiere8 from "../img/cancelli-ringhiere/DSC00435-scaled.jpg";
import CancelliRinghiere9 from "../img/cancelli-ringhiere/DSC00412.jpg";
import CancelliRinghiere10 from "../img/cancelli-ringhiere/foto.jpg";
import CancelliRinghiere11 from "../img/cancelli-ringhiere/cancelli6.png";
import CancelliRinghiere12 from "../img/cancelli-ringhiere/110-1.jpg";
import CancelliRinghiere13 from "../img/cancelli-ringhiere/424-1.jpg";
import CancelliRinghiere14 from "../img/cancelli-ringhiere/424.jpg";
import CancelliRinghiere15 from "../img/cancelli-ringhiere/cancelli5.png";
import CancelliRinghiere16 from "../img/cancelli-ringhiere/foto1.jpg";
import CancelliRinghiere17 from "../img/cancelli-ringhiere/cancelli4.png";
import CancelliRinghiere18 from "../img/cancelli-ringhiere/cancelli2.png";
import CancelliRinghiere19 from "../img/cancelli-ringhiere/balaustre1.png";
import CancelliRinghiere20 from "../img/cancelli-ringhiere/balaustre2.png";
import CancelliRinghiere21 from "../img/cancelli-ringhiere/balaustre3.png";
import CancelliRinghiere22 from "../img/cancelli-ringhiere/19.jpg";
import CancelliRinghiere23 from "../img/cancelli-ringhiere/18.jpg";
import CancelliRinghiere24 from "../img/cancelli-ringhiere/20.jpg";
import CancelliRinghiere25 from "../img/cancelli-ringhiere/balaustre4.png";
import CancelliRinghiere26 from "../img/cancelli-ringhiere/balaustre7.png";
import CancelliRinghiere27 from "../img/cancelli-ringhiere/balaustre6.png";
import CancelliRinghiere28 from "../img/cancelli-ringhiere/balaustre5.png";

//cancelli e ringhiere
import ScaleCorrimano1 from "../img/scale-corrimano/scale7.png";
import ScaleCorrimano2 from "../img/scale-corrimano/scale4.png";
import ScaleCorrimano3 from "../img/scale-corrimano/scale5.png";
import ScaleCorrimano4 from "../img/scale-corrimano/scale6.png";
import ScaleCorrimano5 from "../img/scale-corrimano/scale1.png";
import ScaleCorrimano6 from "../img/scale-corrimano/scale2.png";
import ScaleCorrimano7 from "../img/scale-corrimano/scale8.png";
import ScaleCorrimano8 from "../img/scale-corrimano/scale3.png";
import ScaleCorrimano9 from "../img/scale-corrimano/interni-1.jpg";
import ScaleCorrimano10 from "../img/scale-corrimano/interni-2.jpg";
import ScaleCorrimano11 from "../img/scale-corrimano/interni-3.jpg";
import ScaleCorrimano12 from "../img/scale-corrimano/interni-4.jpg";

//vetrate
import Vetrate1 from "../img/vetrate/PHOTO-2019-05-06-14-29-04-1.jpg";
import Vetrate2 from "../img/vetrate/PHOTO-2019-05-06-14-29-04-2.jpg";
import Vetrate3 from "../img/vetrate/PHOTO-2019-05-06-14-29-04-3.jpg";
import Vetrate4 from "../img/vetrate/PHOTO-2019-05-06-14-29-04-4.jpg";
import Vetrate5 from "../img/vetrate/PHOTO-2019-05-06-14-29-04.jpg";
import Vetrate6 from "../img/vetrate/vetrate-small.png";
import Vetrate7 from "../img/vetrate/IMG_6722.jpg";

//tanto altro
import TantoAltro1 from "../img/altro/IMG_8742.jpg";
import TantoAltro2 from "../img/altro/IMG_8746.jpg";
import TantoAltro3 from "../img/altro/IMG_6721-rotated.jpg";
import TantoAltro4 from "../img/altro/100_0008.jpg";
import TantoAltro5 from "../img/altro/100_0009.jpg";
import TantoAltro6 from "../img/altro/100_0007.jpg";
import TantoAltro7 from "../img/altro/IMG-2925-scaled.jpg";
import TantoAltro8 from "../img/altro/20161118_105524.jpg";
import TantoAltro9 from "../img/altro/IMG_1208.jpg";
import TantoAltro10 from "../img/altro/IMG-2919-scaled.jpg";
import TantoAltro11 from "../img/altro/IMG-2923-scaled.jpg";
import TantoAltro12 from "../img/altro/IMG_2278.jpg";
import TantoAltro13 from "../img/altro/IMG_2279.jpg";
import TantoAltro14 from "../img/altro/IMG_2277.jpg";
//38 more
import TantoAltro15 from "../img/altro/DSC_2414.jpg";
import TantoAltro16 from "../img/altro/DSC_2441.jpg";
import TantoAltro17 from "../img/altro/DSC_2490.jpg";
import TantoAltro18 from "../img/altro/DSC_2513.jpg";
import TantoAltro19 from "../img/altro/DSC_2525.jpg";
import TantoAltro20 from "../img/altro/DSC_2541.jpg";
import TantoAltro21 from "../img/altro/Immagine.jpg";
import TantoAltro22 from "../img/altro/MA_6862.jpg";
import TantoAltro23 from "../img/altro/MA_6860.jpg";
import TantoAltro24 from "../img/altro/MA_6859.jpg";
import TantoAltro25 from "../img/altro/MA_6858.jpg";
import TantoAltro26 from "../img/altro/MA_6857.jpg";
import TantoAltro27 from "../img/altro/MA_6856.jpg";
import TantoAltro28 from "../img/altro/MA_6854.jpg";
import TantoAltro29 from "../img/altro/MA_6852.jpg";
import TantoAltro30 from "../img/altro/MA_6850.jpg";
import TantoAltro31 from "../img/altro/MA_6847.jpg";
import TantoAltro32 from "../img/altro/MA_6846.jpg";
import TantoAltro33 from "../img/altro/MA_6845p-copia-1.jpg";
import TantoAltro34 from "../img/altro/MA_6845p-copia-scaled.jpg";
import TantoAltro35 from "../img/altro/MA_6868.jpg";
import TantoAltro37 from "../img/altro/AX_9316.jpg";
import TantoAltro38 from "../img/altro/AX_9324-1.jpg";
import TantoAltro39 from "../img/altro/AX_9326.jpg";
import TantoAltro40 from "../img/altro/AX_9328.jpg";
import TantoAltro41 from "../img/altro/AX_9329.jpg";
import TantoAltro42 from "../img/altro/AX_9332.jpg";
import TantoAltro43 from "../img/altro/AX_9334.jpg";
import TantoAltro44 from "../img/altro/AX_9335.jpg";
import TantoAltro45 from "../img/altro/AX_9337.jpg";
import TantoAltro46 from "../img/altro/AX_9342.jpg";
import TantoAltro47 from "../img/altro/AX_9344.jpg";
import TantoAltro48 from "../img/altro/AX_9349.jpg";
import TantoAltro49 from "../img/altro/AX_9351.jpg";
import TantoAltro50 from "../img/altro/MA_5062.jpg";
import TantoAltro51 from "../img/altro/MA_5083.jpg";

//Products Hero
import CombinatoSmall from "../img/combinato-small-1.png";
import PersianeSmall from "../img/persiane-small.png";
import PersianaCentroStorico from "../img/small.jpg";
import Portelloni from "../img/portelloni.jpg";
import GrateSmall from "../img/grate-small.png";
import GrataSnodo from "../img/grate-snodo-small.png";
import GrataFutura from "../img/futura-small.png";
import PortoneSmall from "../img/portoni-ferro-small.png";
import CancelliRinghiere from "../img/cancelli-small.png";
import ScaleCorrimano from "../img/scale-small.png";
import Vetrate from "../img/vetrate-small-1.png";
import TantoAltro from "../img/cosa-realizziamo.jpg"


const products = [
  {
    id: "combinato",
    title: "Combinato",
    description: "Doppia serratura per una doppi sicurezza",
    text: "Combinato è la soluzione ideale per chi cerca una doppia sicurezza. La serratura triplice di sicurezza in acciaio Inox, dotata di mezzo cilindro europeo, sia sulla grata che sulla persiana, di serie. Paletti verticali di chiusura in acciaio zincato, dimetro 12,5 mm, che si inseriscono all'interno del telaio per 2cm; su entrambe le ante. Cerniere con regolazione in altezza dotate di perni e cuscinetti. Ante dotate di rostri parastrappo di sicurezza, a bocca di lupo, che si inseriscono all'interno del telaio. Sezione in fase di allestimento.",
    data: {
      "serratura": {
        img: [Combinato1, Combinato2, Combinato3, Combinato4],
        title: "Serratura",
        description: "Serratura triplice di sicurezza in acciaio Inox, dotata di mezzo cilindro europeo, sia sulla grata che sulla persiana, di serie."
      },
      "paletti": {
        img: [Combinato5, Combinato6, Combinato7, Combinato8, Combinato9],
        title: "Paletti di chiusura",
        description: "Paletti verticali di chiusura in acciaio zincato, dimetro 12,5 mm, che si inseriscono all'interno del telaio per 2cm; su entrambe le ante."
      },
      "cerniere": {
        img: [Combinato10, Combinato11],
        title: "Cerniere e rostri",
        description: "Cerniere con regolazione in altezza dotate di perni e cuscinetti. Ante dotate di rostri parastrappo di sicurezza, a bocca di lupo, che si inseriscono all'interno del telaio."
      },
      "allarmi": {
        img: [],
        title: "Allarmi",
        description: "Sezione in fase di allestimento."
      },
      "certificazioni": {
        img: [Combinato12],
        title: "Certificazioni",
        description: false
      },
      "altre": {
        img: [Combinato13, Combinato14, Combinato15, Combinato16, Combinato17, Combinato18, Combinato19, Combinato20, Combinato21, Combinato22, Combinato23, Combinato24, Combinato25, Combinato26, Combinato27, Combinato28, Combinato29, Combinato30, Combinato31],
        title: "Altre Immagini",
        description: false
      }
    },
    main: CombinatoGif,
    to: '/product/combinato'
  },
  {
    id: "persiane",
    title: "Persiane",
    description: "Classica ed Intramontabile",
    text: "Le persiane sono un classico intramontabile, che si adatta a qualsiasi tipo di ambiente. La serratura triplice di sicurezza in acciaio Inox, dotata di mezzo cilindro europeo di serie. Paletti verticali di chiusura in acciaio zincato, dimetro 12,5 mm, che si inseriscono all'interno del telaio per 2cm; su entrambe le ante. Cerniere con regolazione in altezza dotate di perni e cuscinetti. Ante dotate di rostri parastrappo di sicurezza, a bocca di lupo, che si inseriscono all'interno del telaio. Sezione in fase di allestimento.",
    data: {
      "serratura": {
        img: [Persiane1, Persiane2],
        title: "Serratura",
        description: "Serratura triplice di sicurezza in acciaio Inox, dotata di mezzo cilindro europeo di serie."
      },
      "cerniere-e-rostro": {
        img: [Persiane3, Persiane4, Persiane5],
        title: "Cerniere e rostro",
        description: "Cerniere con regolazione in altezza dotate di perni e cuscinetti. Ante dotate di rostri parastrappo di sicurezza, a bocca di lupo, che si inseriscono all'interno del telaio."
      },
      "paletti-di-chiusura": {
        img: [Persiane6, Persiane7, Persiane8],
        title: "Paletti di chiusura",
        description: "Paletti verticali di chiusura in acciaio zincato, dimetro 12,5 mm, che si inseriscono all'interno del telaio per 2cm; su entrambe le ante."
      },
      "allarme": {
        img: [],
        title: "Allarme",
        description: "Sezione in fase di allestimento."
      },
      "certificazioni": {
        img: [],
        title: "Certificazioni",
        description: "In fase di aggiornamento certificato."
      },
      "altre": {
        img: [Persiane9, Persiane10, Persiane11, Persiane12, Persiane13, Persiane14, Persiane15],
        title: "Altre Immagini",
        description: false
      }
    },
    main: PersianeGif,
    to: '/product/persiane'
  },
  {
    id: "persiana-centro-storico",
    title: "Persiana Centro Storico",
    description: "C'era una volta",
    text: "Persiane  in ferro con telaio presso piegato e soglia realizzati in lamiera zincata da 20/10 con predisposizione fori per alloggiamento accessori, supporti di irrigidimento per rinforzo lamiere e predisposizione per antifurto ibrido. Telaio dotato di rosti para strappo e pressori di registro. Ante complanari zincate, paletti e puntali di chiusura in acciaio; ovaline deformate zincate (come vecchie persiane per centro storico) con riporto centrale/scambio di battuta realizzata con lamiera presso piegata da 15/10; serratura triplice di sicurezza con mezzo cilindro europeo con mezzo cilindro europeo di serie..",
    data: {
      "serratura": {
        img: [PersianaCentroStorico1, PersianaCentroStorico2, PersianaCentroStorico3],
        title: "Serratura",
        description: "Serratura triplice di sicurezza con mezzo cilindro europeo di serie."
      },
      "cerniere-e-rostro": {
        img: [PersianaCentroStorico4, PersianaCentroStorico5],
        title: "Cerniere e rostro",
        description: "Cerniere con regolazione in altezza dotate di perni e cuscinetti. Ante dotate di rostri parastrappo di sicurezza, a bocca di lupo, che si inseriscono all'interno del telaio."
      },
      "paletti-di-chiusura": {
        img: [PersianaCentroStorico6, PersianaCentroStorico7],
        title: "Paletti di chiusura",
        description: "Paletti verticali di chiusura in acciaio zincato, dimetro 12,5 mm, che si inseriscono all'interno del telaio per 2cm; su entrambe le ante."
      },
      "allarme": {
        img: [],
        title: "Allarme",
        description: "Sezione in fase di allestimento."
      },
      "certificazioni": {
        img: [],
        title: "Certificazioni",
        description: "In fase di aggiornamento certificato."
      },
      "altre": {
        img: [PersianaCentroStorico8, PersianaCentroStorico9, PersianaCentroStorico10, PersianaCentroStorico11, PersianaCentroStorico12, PersianaCentroStorico13, PersianaCentroStorico14],
        title: "Altre Immagini",
        description: false
      }
    },
    main: PersianaCentroStoricoGif,
    to: '/product/persiana-centro-storico'
  },
  {
    id: "portelloni",
    title: "Portelloni",
    description: "Dal passato al futuro",
    text: "Portelloni in ferro con telaio presso piegato e soglia realizzati in lamiera zincata da 20/10 con predisposizione fori per alloggiamento accessori, supporti di irrigidimento per rinforzo lamiere e predisposizione per antifurto ibrido. Telaio dotato di rosti para strappo e pressori di registro. Ante complanari zincate, paletti e puntali di chiusura in acciaio; ovaline deformate zincate (come vecchie persiane per centro storico) con riporto centrale/scambio di battuta realizzata con lamiera presso piegata da 15/10; serratura triplice di sicurezza con mezzo cilindro europeo con mezzo cilindro europeo di serie.",
    data: {
      "serratura": {
        img: [Portelloni1, Portelloni2, Portelloni3, Portelloni4, Portelloni5, Portelloni6, Portelloni7, Portelloni8, Portelloni9, Portelloni10, Portelloni11],
        title: "Serratura",
        description: "Serratura triplice di sicurezza in acciaio Inox, dotata di mezzo cilindro europeo di serie."
      },
      "cerniere-e-rostro": {
        img: [Portelloni12, Portelloni13, Portelloni14, Portelloni15, Portelloni16],
        title: "Cerniere e rostro",
        description: "Cerniere con regolazione in altezza dotate di perni e cuscinetti. Ante dotate di rostri parastrappo di sicurezza, a bocca di lupo, che si inseriscono all'interno del telaio."
      },
      "paletti-di-chiusura": {
        img: [Portelloni17, Portelloni18, Portelloni19, Portelloni20, Portelloni21, Portelloni22, Portelloni23, Portelloni24, Portelloni25, Portelloni26],
        title: "Paletti di chiusura",
        description: "Paletti verticali di chiusura in acciaio zincato, dimetro 12,5 mm, che si inseriscono all'interno del telaio per 2cm; su entrambe le ante."
      },
      "allarme": {
        img: [Portelloni27, Portelloni28],
        title: "Allarme",
        description: null
      },
      "certificazioni": {
        img: [],
        title: "Certificazioni",
        description: "In fase di aggiornamento certificato."
      },
      "altre": {
        img: [Portelloni29, Portelloni30, Portelloni31, Portelloni32, Portelloni33, Portelloni34, Portelloni35, Portelloni36],
        title: "Altre Immagini",
        description: false
      }
    },
    main: PortelloniGif,
    to: '/product/portelloni'
  },
  {
    id: "grate",
    title: "Grata",
    description: "Personalizza con il tuo stile",
    text:"Grata in ferro con telaio presso piegato e soglia realizzati in lamiera zincata da 20/10 con predisposizione fori per alloggiamento accessori, supporti di irrigidimento per rinforzo lamiere e predisposizione per antifurto ibrido. Telaio dotato di rosti para strappo e pressori di registro. Ante complanari zincate, paletti e puntali di chiusura in acciaio; disegno interno zincato con tondo o quadro del 16. Riporto centrale/scambio di battuta realizzata con lamiera presso piegata da 15/10; serratura triplice di sicurezza con mezzo cilindro europeo di serie. Le nostre grate antintrusione, sia fisse che apribili, oltre a garantire la massima sicurezza contro i tentativi di furto ed effrazione, sono appositamente studiate per soddisfare le esigenze ed i gusti estetici dei nostri clienti. Avvalendoci di tecnologie innovative siamo in grado di realizzare con il Taglio al Plasma o Laser (su lamiere in acciaio) svariati disegni.",
    data: {
      "serratura": {
        img: [Grate1, Grate2],
        title: "Serratura",
        description: "Serratura triplice di sicurezza in acciaio Inox, dotata di mezzo cilindro europeo di serie."
      },
      "paletti-di-chiusura": {
        img: [Grate3, Grate4, Grate5],
        title: "Paletti di chiusura",
        description: "Paletti verticali di chiusura in acciaio zincato, dimetro 12,5 mm, che si inseriscono all'interno del telaio per 2cm; su entrambe le ante."
      },
      "cerniere-e-rostro": {
        img: [Grate6, Grate7, Grate8, Grate9],
        title: "Cerniere e rostro",
        description: "Cerniere con regolazione in altezza dotate di perni e cuscinetti. Ante dotate di rostri parastrappo di sicurezza, a bocca di lupo, che si inseriscono all'interno del telaio."
      },
      "allarme": {
        img: [],
        title: "Allarme",
        description: "Sezione in fase di allestimento."
      },
      "certificazioni": {
        img: [],
        title: "Certificazioni",
        description: "In fase di aggiornamento certificato."
      },
      "altre": {
        img: [Grate10, Grate11, Grate12, Grate13, Grate14, Grate15, Grate16],
        title: "Altre Immagini",
        description: false
      }
    },
    main: GrateGif,
    to: '/product/grate'
  },
  {
    id: "grate-a-snodo",
    title: "Grate a Snodo",
    description: "Soluzione a portata di mano",
    text: "La linea Grata a Snodo è interamente realizzata, in ogni sua parte, presso le officine della MARROCCO GROUP. E’ un prodotto studiato appositamente per adattarsi a delle esigenze particolari, si può inserire tra persiana e finestra, garantendo comunque la massima sicurezza della casa contro i tentativi di furto ed effrazioni. Viene costruita con profili in tubolare zincato da 40 x 30 mm. E' la Grata ideale per proteggere la vostra casa. Robusta, ottimamente rifinita, supera qualsiasi confronto, La Grata viene realizzata su misura, zincata per la massima resistenza alla corrosione, anche in atmosfera chimicamente aggressiva, come l'ambiente marino. La particolarità di questa grata è anche la sua praticità, che si può aprire sia all'esterno che all'interno.",
    data: {
      "doppia-serratura": {
        img: [GrateSnodo1],
        title: "Doppia Serratura",
        description: "Doppia Serratura con 1/2 cilindro europeo."
      },
      "paletti-chiusura-acciaio-zincato": {
        img: [GrateSnodo2, GrateSnodo3, GrateSnodo4, GrateSnodo5],
        title: "Paletti di chiusura in Acciaio Zincato",
        description: "Paletti di chiusura in Acciaio zincato diam. 12,5 mm. su ambo le ante."
      },
      "snodo": {
        img: [GrateSnodo6, GrateSnodo7],
        title: "Snodo",
        description: "Snodo superiore ed inferiore."
      },
    },
    main: GrateSnodoGif,
    to: '/product/grate-a-snodo'
  },
  {
    id: "grate-futura",
    title: "Grate Futura",
    description: "Punta di diamante il gusto incontra l'estro",
    text: "La linea Grata Futura è interamente realizzata, in ogni sua parte, presso le officine della MARROCCO GROUP. E’ un prodotto studiato appositamente per garantire la massima sicurezza della casa di abitazione contro i tentativi di furto ed effrazioni. Viene costruita con profilo telaio in acciaio zincato da 20/10 e l’anta in acciaio monolite a taglio laser sp. 10 mm., battuta centrale fissata sia all’interno che all’esterno con punti di saldatura. E’ la Grata ideale per proteggere la vostra casa. Robusta, ottimamente rifinita, supera qualsiasi confronto, La Grata Futura viene realizzata su misura, zincata per la massima resistenza alla corrosione, anche in atmosfera chimicamente aggressiva, come l'ambiente marino.",
    data: {
      "serratuta": {
        img: [GrateFutura1, GrateFutura2, GrateFutura3],
        title: "Serratura",
        description: "Serratura triplice di sicurezza in acciaio Inox, dotata di mezzo cilindro europeo di serie."
      },
      "paletti-di-chiusura": {
        img: [GrateFutura4, GrateFutura5, GrateFutura6, GrateFutura7, GrateFutura8],
        title: "Paletti di chiusura",
        description: "Paletti verticali di chiusura in acciaio zincato, dimetro 12,5 mm, che si inseriscono all'interno del telaio per 2cm; su entrambe le ante."
      },
      "cerniere-e-rostro": {
        img: [GrateFutura9, GrateFutura10, GrateFutura11],
        title: "Cerniere e rostro",
        description: "Cerniere con regolazione in altezza dotate di perni e cuscinetti. Ante dotate di rostri parastrappo di sicurezza, a bocca di lupo, che si inseriscono all'interno del telaio."
      },
      "allarme": {
        img: [],
        title: "Allarme",
        description: "Sezione in fase di allestimento."
      },
      "certificazioni": {
        img: [GrateFutura12],
        title: "Certificazioni",
        description: false
      },
      "altre": {
        img: [GrateFutura13, GrateFutura14],
        title: "Altre Immagini",
        description: false
      }
    },
    main: GrateFuturaGif,
    to: '/product/grata-futura'
  },
  {
    id: "portoni-in-ferro",
    title: "Portoni in Ferro",
    description: "Alternativa al tradizionale",
    text: "Al termine di tutte le lavorazioni ogni nostro prodotto viene sottoposto a pulizia per poi passare alla fase di verniciatura realizzata mediante moderni impianti sia automatici che manuali con vernici in polvere che garantiscono la resistenza nel tempo.",
    data: {
      "immagini": {
        img: [PortoniFerro1, PortoniFerro2, PortoniFerro3, PortoniFerro4],
        title: "Immagini",
        description: false
      },
    },
    main: PortoniFerroGif,
    to: '/product/portoni-in-ferro'
  },
  {
    id: "cancelli-e-ringhiere",
    title: "Cancelli e Ringhiere",
    text: "Al termine di tutte le lavorazioni ogni nostro prodotto viene sottoposto a pulizia per poi passare alla fase di verniciatura realizzata mediante moderni impianti sia automatici che manuali con vernici in polvere che garantiscono la resistenza nel tempo.",
    data: {
      "immagini": {
        img: [CancelliRinghiere1, CancelliRinghiere2, CancelliRinghiere3, CancelliRinghiere4, CancelliRinghiere5, CancelliRinghiere6, CancelliRinghiere7, CancelliRinghiere8, CancelliRinghiere9, CancelliRinghiere10, CancelliRinghiere11, CancelliRinghiere12, CancelliRinghiere13, CancelliRinghiere14, CancelliRinghiere15, CancelliRinghiere16, CancelliRinghiere17, CancelliRinghiere18, CancelliRinghiere19, CancelliRinghiere20, CancelliRinghiere21, CancelliRinghiere22, CancelliRinghiere23, CancelliRinghiere24, CancelliRinghiere25, CancelliRinghiere26, CancelliRinghiere27, CancelliRinghiere28],
        title: "Immagini",
        description: false
      },
    },
    main: CancelliRinghiereGif,
    to: '/product/cancelli-e-ringhiere'
  },
  {
    id: "scale-e-corrimano",
    title: "Scale e Corrimano",
    text: "Al termine di tutte le lavorazioni ogni nostro prodotto viene sottoposto a pulizia per poi passare alla fase di verniciatura realizzata mediante moderni impianti sia automatici che manuali con vernici in polvere che garantiscono la resistenza nel tempo.",
    description: "Personalita' e grinta",
    data: {
      "immagini": {
        img: [ScaleCorrimano1, ScaleCorrimano2, ScaleCorrimano3, ScaleCorrimano4, ScaleCorrimano5, ScaleCorrimano6, ScaleCorrimano7, ScaleCorrimano8, ScaleCorrimano9, ScaleCorrimano10, ScaleCorrimano11, ScaleCorrimano12],
        title: "Immagini",
        description: false
      },
    },
    main: ScaleCorrimanoGif,
    to: '/product/scale-e-corrimano'
  },
  {
    id: "vetrate",
    title: "Vetrate",
    description: "Eleganza e semplicita'",
    text: "Vetrate in ferro per interno ed esterno; realizzazione giardini d'inverno. Pannelli, vetri antinfortunistici, pannelli/vetri antinfortunistici. Colori a scelta su vasta gamma. Al termine di tutte le lavorazioni ogni nostro prodotto viene sottoposto a pulizia per poi passare alla fase di verniciatura realizzata mediante moderni impianti sia automatici che manuali con vernici in polvere che garantiscono la resistenza nel tempo.",
    data: {
      "immagini": {
        img: [Vetrate1, Vetrate2, Vetrate3, Vetrate4, Vetrate5, Vetrate6, Vetrate7],
        title: "Immagini",
        description: false
      },
    },
    main: VetrateGif,
    to: '/product/vetrate'
  },
  {
    id: "tanto-altro",
    title: "Tanto Altro",
    description: "Tanto altro ancora",
    text: "Al termine di tutte le lavorazioni ogni nostro prodotto viene sottoposto a pulizia per poi passare alla fase di verniciatura realizzata mediante moderni impianti sia automatici che manuali con vernici in polvere che garantiscono la resistenza nel tempo.",
    data: {
      "interni": {
        img: [TantoAltro1, TantoAltro2, TantoAltro3, TantoAltro4, TantoAltro5, TantoAltro6],
        title: "Interni",
        description: false
      },
      "esterni": {
        img: [TantoAltro7, TantoAltro8, TantoAltro9, TantoAltro10, TantoAltro11, TantoAltro12, TantoAltro13, TantoAltro14],
        title: "Esterni",
        description: false
      },
      "estro-e-fantasia": {
        img: [TantoAltro15, TantoAltro16, TantoAltro17, TantoAltro18, TantoAltro19, TantoAltro20, TantoAltro21, TantoAltro22, TantoAltro23, TantoAltro24, TantoAltro25, TantoAltro26, TantoAltro27, TantoAltro28, TantoAltro29, TantoAltro30, TantoAltro31, TantoAltro32, TantoAltro33, TantoAltro34, TantoAltro35, TantoAltro37, TantoAltro38, TantoAltro39, TantoAltro40, TantoAltro41, TantoAltro42, TantoAltro43, TantoAltro44, TantoAltro45, TantoAltro46, TantoAltro47, TantoAltro48, TantoAltro49, TantoAltro50, TantoAltro51],
        title: "Estro e Fantasia",
        description: false
      },
    },
    main: TantoAltroGif,
    to: '/product/tanto-altro'
  },
];

const productsHero = [
  {
    title: "Combinato",
    description: "Doppia serratura per una doppi sicurezza",
    image: CombinatoSmall,
    to: '/product/combinato'
  },
  {
    title: "Persiane",
    description: "Classica ed Intramontabile",
    image: PersianeSmall,
    to: '/product/persiane'
  },
  {
    title: "Persiana Centro Storico",
    description: "C'era una volta",
    image: PersianaCentroStorico,
    to: '/product/persiana-centro-storico'
  },
  {
    title: "Portelloni",
    description: "Dal passato al futuro",
    image: Portelloni,
    to: '/product/portelloni'
  },
  {
    title: "Grata",
    description: "Personalizza con il tuo stile",
    image: GrateSmall,
    to: '/product/grate'
  },
  {
    title: "Grate a Snodo",
    description: "Soluzione a portata di mano",
    image: GrataSnodo,
    to: '/product/grate-a-snodo'
  },
  {
    title: "Grate Futura",
    description: "Punta di diamante il gusto incontra l'estro",
    image: GrataFutura,
    to: '/product/grate-futura'
  },
  {
    title: "Portoni in Ferro",
    description: "Alternativa al tradizionale",
    image: PortoneSmall,
    to: '/product/portoni-in-ferro'
  },
  {
    title: "Cancelli e Ringhiere",
    description: "Circondati con stile",
    image: CancelliRinghiere,
    to: '/product/cancelli-e-ringhiere'
  },
  {
    title: "Scale e Corrimano",
    description: "Personalita' e grinta",
    image: ScaleCorrimano,
    to: '/product/scale-e-corrimano'
  },
  {
    title: "Vetrate",
    description: "Eleganza e semplicita'",
    image: Vetrate,
    to: '/product/vetrate'
  },
  {
    title: "Tanto Altro",
    description: "Tanto altro ancora",
    image: TantoAltro,
    to: '/product/tanto-altro'
  },
];

export { products, productsHero };