import React from "react";

import HomeImage1 from "../assets/img/background-1.jpg";
import HomeVideo1 from "../assets/vid/background-1.mp4";

export default function AboutUs() {
  return (
    <section id="about" className="bg-white anchor">
      <div className="flex flex-col xl:flex-row justify-between items-center p-10 bg-white mx-0 xl:mx-2 2xl:mx-40">
        <div className="max-w-lg mb-10 lg:mb-0">
          <h2 className="text-sm text-gray-700 mb-2">A Proposito di Noi</h2>
          <h1 className="text-3xl lg:text-4xl text-black mb-4">
            Benvenuti da Ferro Marrocco
          </h1>
          <p className="text-base text-gray-500 mb-3">
            La Marrocco è sinonimo di eccellenza nella lavorazione del ferro, realizzando prodotti di alta qualità rigorosamente Made in Italy. Prestiamo particolare attenzione alle esigenze dei clienti, fornendo soluzioni dove il ferro è il protagonista delle finiture per esterni e interni.
          </p>
          <p className="text-base text-gray-500 mb-6">
            Collaboriamo con tecnici specializzati e utilizziamo macchinari di ultima generazione per garantire risultati eccellenti. Ogni prodotto viene testato sia meccanicamente che manualmente prima della consegna, assicurando massima qualità e funzionalità.
          </p>
          <p className="text-3xl text-gray-700 caveat-text">Marrocco Group.</p>
        </div>
        <div className="relative bg-white shadow-lg">
          <div className="absolute inset-0 border-2 border-[#1d506b] rounded-lg translate-x-3 -translate-y-3 transform pointer-events-none z-0"></div>
          <video 
            src={HomeVideo1} 
            className="w-auto h-[200px] rounded-lg lg:w-[600px] lg:h-[350px] lg:mb-0 object-cover relative z-10"
            autoPlay
            loop
            muted
          />
        </div>
      </div>
      <div className="border-b border-gray-400 md:mx-64 mx-12"></div>
      <div className="flex flex-col xl:flex-row justify-center items-center p-10 bg-white mx-0 xl:mx-2 2xl:mx-32">
        <div className="w-full mx-auto mb-10 lg:mb-0">
          <h2 className="text-sm text-gray-700 mb-2">Siamo una solida azienda che ha saputo rinnovarsi</h2>
          <h1 className="text-3xl lg:text-4xl text-black mb-4">
            Marchiando nel Ferro tre parole Indelebili:
          </h1>
          <div id="bandiera" className="flex mb-6 justify-start oswald-text text-[18px] md:text-[20px] text-slate-90">
            <span className="bg-[#009246] px-2 md:px-8 py-3 rounded-l-lg border-y-2 border-l-2 border-black">PASSIONE</span>
            <span className="bg-[#ffffff] px-2 md:px-8 py-3 border-y-2 border-black">ESPERIENZA</span>
            <span className="bg-[#ce2b37] px-2 md:px-8 py-3 rounded-r-lg border-y-2 border-r-2 border-black">GARANZIA</span>
          </div>
          <div className="flex flex-col md:flex-row justify-between space-x-0 md:space-x-6">
            <div className="w-full md:w-1/2 mx-auto items-center mb-6 md:mb-0">
              <p className="text-base text-gray-500 mb-3">
                La lavorazione, i materiali, l’originalità e la qualità con cui la Marrocco realizza prodotti per i propri clienti sono orgogliosamente e rigorosamente Made in Italy.
              </p>
              <p className="text-base text-gray-500 mb-3">
                Prestiamo particolare attenzione alle esigenze dei nostri clienti fornendo soluzioni dove il ferro è il vero protagonista delle finiture di esterni ed interni.
              </p>
              <p className="text-base text-gray-500 mb-3">
                Grazie alla notevole esperienza, conoscenza e passione per il nostro settore, la Marrocco è in grado di realizzare e fornire una vasta gamma di prodotti esclusivi, unici nel loro genere, frutto dell’incontro di artisti del ferro con lavorazioni all’avanguardia.
              </p>
            </div>
            <div className="hidden md:block border-r border-gray-400 mx-6"></div>
            <div className="w-full md:w-1/2 mx-auto items-center">
              <p className="text-base text-gray-500 mb-3">
                Avvalendoci della collaborazione di tecnici altamente specializzati e con l’ausilio di macchinari di ultima generazione, garantiamo ottimi risultati nella soddisfazione di ogni esigenza.
              </p>
              <p className="text-base text-gray-500 mb-3">
                Poniamo particolare attenzione al controllo di qualità. Ogni nostro prodotto viene testato sia meccanicamente che manualmente prima di essere consegnato, in modo da avere la certezza di fornire ai nostri clienti soltanto la massima qualità e funzionalità.
              </p>
              <p className="text-base text-gray-500 mb-6">
                Ciò che rende unici i nostri lavori è il mix perfetto tra tecnologia e cura manuale, che mira ad un risultato eccellente nei minimi dettagli.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b border-gray-400 md:mx-64 mx-12"></div>
      {/* Add the third section here */}
      <div className="flex flex-col xl:flex-row justify-between items-center p-10 bg-white mx-0 xl:mx-2 2xl:mx-40">
        <div className="relative bg-white shadow-lg">
          <div className="absolute inset-0 border-2 border-[#1d506b] rounded-lg translate-x-3 -translate-y-3 transform pointer-events-none z-0"></div>
          <img 
            src={HomeImage1} 
            className="w-auto h-[200px] rounded-lg lg:w-[600px] lg:h-[350px] lg:mb-0 object-cover relative z-10"
            alt="Home"
          />
        </div>
        <div className="max-w-lg mb-10 lg:mb-0">
          <h2 className="text-sm text-gray-700 mb-2">A Proposito di Noi</h2>
          <h1 className="text-3xl lg:text-4xl text-black mb-4">
            La nostra Missione
          </h1>
          <p className="text-base text-gray-500 mb-3">
            Creare prodotti con il ferro che incontrino contemporaneamente le esigenze di sicurezza delle persone e il naturale desiderio di vivere e lavorare in ambienti accoglienti e confortevoli.
          </p>
          <p className="text-base text-gray-500 mb-6">
            Progettare e realizzare sistemi di sicurezza all’avanguardia o “semplici” oggetti come scale, ringhiere, cancelli e tanto altro con elementi di originalità e design.
          </p>
        </div>
      </div>
    </section>
  );
}
